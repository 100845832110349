export const templates = [
  {
    id: 1,
    name: "Adventure",
    description: "An adventurous journey.",
    settings: {
      characterName: "Jack",
      age: "7",
      gender: "Boy",
      theme: "Adventure (e.g., Where the Wild Things Are)",
      challenge: "find their way home",
      setting: "Fantasy world (e.g., Alice in Wonderland)",
      ageGroup: "Around 4",
      length: 20,
      narrativeRepetition: false,
      characterRepetition: false,
      rhyme: true,
      includeImageDescriptions: false,
      pageSpread: true,
      imageLayout: "Single-page image",
    },
  },
  {
    id: 2,
    name: "Fantasy",
    description: "A fantastical tale.",
    settings: {
      characterName: "Luna",
      age: "6",
      gender: "Girl",
      theme: "Fantasy world (e.g., Alice in Wonderland)",
      challenge: "uncover a secret",
      setting: "Natural world (e.g., The Lorax)",
      ageGroup: "Closer to 5",
      length: 20,
      narrativeRepetition: true,
      characterRepetition: true,
      rhyme: false,
      includeImageDescriptions: false,
      pageSpread: true,
    },
  },
  {
    id: 3,
    name: "Empty",
    description: "Create your own story from scratch.",
    settings: {
      characterName: "",
      age: "",
      gender: "",
      theme: "",
      challenge: "",
      setting: "",
      ageGroup: "",
      length: "",
      narrativeRepetition: false,
      characterRepetition: false,
      rhyme: false,
      includeImageDescriptions: false,
      pageSpread: true,
    },
  },
];
