import { useStory } from '../context/StoryContext';
import { createTextBlock, updateTextBlock, deleteTextBlock } from '../services/textBlockService';


const useTextBlocks = (pageId) => {
  const { storyState, dispatch } = useStory();
  const page = storyState.pages.find(page => page.pageId === pageId);
  const text_blocks = page.text_blocks;
  const addNewTextBlock = async (x, y) => {
    try {
      console.log("BRUH PAGE ID: ", pageId)
      const newTextBlock = await createTextBlock(pageId, x, y);
      dispatch({
        type: 'ADD_NEW_TEXT_BLOCK',
        payload: { pageId, textBlock: newTextBlock },
      });
    } catch (error) {
      console.error('Error adding new text block:', error);
    }
  };

  const handleDeleteTextBlock = async (textBlockId) => {
    try {
      if (text_blocks.length > 1) {
        await deleteTextBlock(textBlockId);
        dispatch({
          type: 'DELETE_TEXT_BLOCK',
          payload: { pageId: page.pageId, textBlockId },
        });
      } else {
        alert('Cannot delete only text block on page.');
      }
    } catch (error) {
      console.error('Error deleting text block:', error);
    }
  };

  const handleSaveTextBlock = async (textBlock) => {
    try {
      await updateTextBlock(textBlock);
      console.log('Text block saved successfully');
    } catch (error) {
      console.error('Error saving text block:', error);
    }
  };

  const handleUpdateTextBlock = async (updatedTextBlock) => {
    try {
      dispatch({
        type: 'UPDATE_PAGE_TEXT_BLOCKS',
        payload: { pageId: page.pageId, updatedTextBlock}
      });
    } catch (error) {
      console.error('Error updating text block:', error);
    }
  };
  return { text_blocks, addNewTextBlock, handleDeleteTextBlock, handleUpdateTextBlock, handleSaveTextBlock };
};

export default useTextBlocks;
