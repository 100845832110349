import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PremadeBookCard from '../components/PremadeBookCard';
import TemplateCard from '../components/TemplateCard';
import QuickStartSection from '../components/QuickStartSection';
import { templates } from '../data/Templates';
import BookPreviewModal from '../components/BookPreviewModal';
import { useStory } from '../context/StoryContext';
import { BookOpenIcon, RectangleGroupIcon, BoltIcon } from '@heroicons/react/24/outline';
import LoadingOverlay from '../components/LoadingOverlay';
const HomePage = () => {
  const navigate = useNavigate();
  const [previewBook, setPreviewBook] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { generatingStory } = useStory();
  const [isGenerating, setIsGenerating] = useState(false);

  const handleWriteStory = async (settings) => {
    try {
      setIsGenerating(true);
      const storyData = await generatingStory(settings);
      setIsGenerating(false);
      navigate(`/writer/${storyData.bookId}`);
    } catch (error) {
      console.error('Failed to generate story:', error);
      setIsGenerating(false);
    }
  };

  const handlePreviewOpen = (book) => setPreviewBook(book);
  const handlePreviewClose = () => setPreviewBook(null);
  const handleTemplateClick = (template) => setSelectedTemplate(template);
  const handleCloseTemplateModal = () => setSelectedTemplate(null);

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <h1 className="text-4xl font-bold mb-8 text-gray-800">Create Your Story</h1>

      <Section title="Featured Book" icon={BookOpenIcon}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <PremadeBookCard bookId={221} onPreviewOpen={handlePreviewOpen} />
          <PremadeBookCard bookId={228} onPreviewOpen={handlePreviewOpen} />
          <PremadeBookCard bookId={229} onPreviewOpen={handlePreviewOpen} />
        </div>
      </Section>

      <Section title="Story Templates" icon={RectangleGroupIcon}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {templates.map(template => (
            <div key={template.id} className="relative">
              <div
                className="block p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                onClick={() => handleTemplateClick(template)}
              >
                <h2 className="text-xl font-semibold mb-2 text-gray-800">{template.name}</h2>
                <p className="text-gray-600 mb-4">{template.description}</p>
                <button className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-300 flex items-center justify-center">
                  <BookOpenIcon className="w-5 h-5 mr-2" />
                  Write Story
                </button>
              </div>
              {selectedTemplate && selectedTemplate.id === template.id && (
                <TemplateCard
                  template={selectedTemplate}
                  onWriteStory={handleWriteStory}
                  isOpen={true}
                  onClose={handleCloseTemplateModal}
                  initialSettings={selectedTemplate.settings}
                />
              )}
            </div>
          ))}
        </div>
      </Section>

      <Section title="Quick Start" icon={BoltIcon}>
        <QuickStartSection onWriteStory={handleWriteStory} />
      </Section>

      {previewBook && (
        <BookPreviewModal
          isOpen={!!previewBook}
          handleClose={handlePreviewClose}
          book={previewBook}
        />
      )}
      {isGenerating && <LoadingOverlay />}
    </div>
  );
};

const Section = ({ title, icon: Icon, children }) => (
  <div className="mb-12">
    <div className="flex items-center mb-6">
      <Icon className="w-6 h-6 text-blue-500 mr-2" />
      <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
    </div>
    {children}
  </div>
);

export default HomePage;
