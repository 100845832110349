import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import useStoryEditor from "../hooks/useStoryEditor";
import CollapsibleSidebar from "../components/CollapsibleSidebar";
import TitleInput from "../components/TitleInput";
import CoverPromptInput from "../components/CoverPromptInput";
import ManagePhotosComponent from "../components/ManagePhotosComponent";
import CoverSelector from "../components/CoverSelector";
import { Button } from "../components/ui/button";
const CoverTitleEditorPage = () => {
  const {
    title,
    coverImagePrompt,
    coverOptions,
    isGeneratingCover,
    faceSize,
    facePositioning,
    angleRatio,
    setFaceSize,
    setFacePositioning,
    setAngleRatio,
    handleGenerateCoverOptions,
    handleContinueToBook,
    handleTitleChange,
    handleCoverImagePromptChange,
    handleTitleSave,
    handleSetCover,
    bookId,
    frontCover,
    backCover,
  } = useStoryEditor();
  const navigate = useNavigate();
  const [userPhotosRequestId, setUserPhotosRequestId] = useState(null);

  const sidebarActions = [
    {
      label: "Continue to Book",
      onClick: handleContinueToBook,
      icon: ArrowRightIcon,
      className: "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
      disabled: false,
    },
  ];
  console.log("FACE SIZE: ", faceSize);
  console.log("FACE POSITIONING: ", facePositioning);
  console.log("ANGLE RATIO: ", angleRatio);

  return (
    <div className="flex w-full min-h-screen bg-gray-100">
      <CollapsibleSidebar actions={sidebarActions} title="Cover Editor">
        <div className="space-y-4">
          <TitleInput
            title={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            onSave={handleTitleSave}
          />
          <CoverPromptInput
            coverImagePrompt={coverImagePrompt}
            onChange={(e) => handleCoverImagePromptChange(e.target.value)}
          />
          <Button
            onClick={handleGenerateCoverOptions}
            disabled={isGeneratingCover || !coverImagePrompt.trim()}
            loading={isGeneratingCover}
            icon={PhotoIcon}
          >
            Generate Cover
          </Button>
          <ManagePhotosComponent
            onSelect={setUserPhotosRequestId}
            userPhotosRequestId={userPhotosRequestId}
            showDeleteButton={false}
          />
          <div className="space-y-4">
            <div>
              <label
                htmlFor="faceSize"
                className="block text-sm font-medium text-gray-700"
              >
                Face Size
              </label>
              <input
                type="text"
                id="faceSize"
                value={faceSize}
                onChange={(e) => setFaceSize(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter a value between 0 and 100"
              />
            </div>
            <div>
              <label
                htmlFor="facePositioning"
                className="block text-sm font-medium text-gray-700"
              >
                Face Positioning
              </label>
              <input
                type="text"
                id="facePositioning"
                value={facePositioning}
                onChange={(e) => setFacePositioning(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter a value between 0 and 100"
              />
            </div>
            <div>
              <label
                htmlFor="angleRatio"
                className="block text-sm font-medium text-gray-700"
              >
                Angle Ratio
              </label>
              <input
                type="text"
                id="angleRatio"
                value={angleRatio}
                onChange={(e) => setAngleRatio(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter a value between 0 and 100"
              />
            </div>
          </div>
        </div>
      </CollapsibleSidebar>
      <div className="grow p-8 overflow-auto">
        <div className="flex justify-between mb-4">
          <Button
            onClick={() => navigate(`/writer/${bookId}`)}
            variant="outline"
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            Back to Writer
          </Button>
          <Button onClick={handleContinueToBook}>
            Continue to Book
            <ArrowRightIcon className="w-4 h-4 ml-2" />
          </Button>
        </div>
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
          <CoverSelector
            coverOptions={coverOptions}
            onSelectCover={handleSetCover}
            onGenerateCovers={handleGenerateCoverOptions}
            isGenerating={isGeneratingCover}
            initialFrontCover={frontCover}
            initialBackCover={backCover}
          />
        </div>
      </div>
    </div>
  );
};

export default CoverTitleEditorPage;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   ArrowLeftIcon,
//   ArrowRightIcon,
//   BookOpenIcon,
//   PencilSquareIcon,
//   PhotoIcon,
// } from "@heroicons/react/24/solid";
// import useStoryEditor from "../hooks/useStoryEditor";
// import CollapsibleSidebar from "../components/CollapsibleSidebar";
// import TitleInput from "../components/TitleInput";
// import CoverPromptInput from "../components/CoverPromptInput";
// import ManagePhotosComponent from "../components/ManagePhotosComponent";
// import CoverOptions from "../components/CoverOptions";
// const CoverTitleEditorPage = () => {
//   const {
//     title,
//     coverImagePrompt,
//     coverOptions,
//     selectedCover,
//     isGeneratingCover,
//     defaultCover,
//     handleGenerateCoverOptions,
//     handleContinueToBook,
//     handleTitleChange,
//     handleCoverImagePromptChange,
//     handleTitleSave,
//     handleSelectCover,
//     bookId,
//     coverImages,
//     handleSetCover,
//   } = useStoryEditor();
//   const navigate = useNavigate();
//   const [userPhotosRequestId, setUserPhotosRequestId] = useState(null);

//   useEffect(() => {
//     if (defaultCover && defaultCover.requestId) {
//       setUserPhotosRequestId(defaultCover.requestId);
//     }
//   }, [defaultCover]);

//   const sidebarActions = [
//     {
//       label: "Continue to Book",
//       onClick: handleContinueToBook,
//       icon: BookOpenIcon,
//       className: "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
//       disabled: false,
//     },
//   ];

//   return (
//     <div className="flex w-full min-h-screen bg-gray-100">
//       <CollapsibleSidebar actions={sidebarActions} title="Cover Editor">
// <div className="space-y-4">
//   <TitleInput
//     title={title}
//     onChange={(e) => handleTitleChange(e.target.value)}
//     onSave={handleTitleSave}
//   />
//   <CoverPromptInput
//     coverImagePrompt={coverImagePrompt}
//     onChange={(e) => handleCoverImagePromptChange(e.target.value)}
//   />
//   <Button
//     onClick={handleGenerateCoverOptions}
//     disabled={isGeneratingCover || !coverImagePrompt.trim()}
//     loading={isGeneratingCover}
//     icon={PhotoIcon}
//   >
//     Generate Cover
//   </Button>
//   <ManagePhotosComponent
//     onSelect={setUserPhotosRequestId}
//     userPhotosRequestId={userPhotosRequestId}
//     showDeleteButton={false}
//   />
// </div>
//       </CollapsibleSidebar>
//       <div className="grow p-8 overflow-auto">
//         <div className="flex justify-between mb-4">
//           <button
//             onClick={() => navigate(`/writer/${bookId}`)}
//             className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors duration-200 flex items-center"
//           >
//             <ArrowLeftIcon className="w-5 h-5 mr-2" />
//             Back to Writer
//           </button>
//           <button
//             onClick={handleContinueToBook}
//             className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
//           >
//             Continue to Book
//             <ArrowRightIcon className="w-5 h-5 ml-2" />
//           </button>
//         </div>
//         <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
//           <CurrentCovers
//             frontCover={coverImages.find((cover) => cover.is_front_cover)}
//             backCover={coverImages.find((cover) => cover.is_back_cover)}
//           />

//           <CoverOptions
//             coverOptions={coverOptions}
//             selectedCover={selectedCover}
//             handleSelectCover={handleSelectCover}
//             handleSetCover={handleSetCover}
//             frontCover={coverImages.find((cover) => cover.is_front_cover)}
//             backCover={coverImages.find((cover) => cover.is_back_cover)}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CoverTitleEditorPage;

// const CurrentCovers = ({ frontCover, backCover }) => (
//   <div className="mb-8">
//     <h3 className="text-xl font-semibold mb-4 text-gray-700">Current Covers</h3>
//     <div className="flex justify-between">
//       {frontCover && (
//         <div className="w-1/2 pr-2">
//           <h4 className="text-lg font-medium mb-2 text-gray-600">
//             Front Cover
//           </h4>
//           <img
//             src={frontCover.imageUrl}
//             alt="Front cover"
//             className="w-full border-4 border-green-500 rounded-lg shadow-md"
//           />
//         </div>
//       )}
//       {backCover && (
//         <div className="w-1/2 pl-2">
//           <h4 className="text-lg font-medium mb-2 text-gray-600">Back Cover</h4>
//           <img
//             src={backCover.imageUrl}
//             alt="Back cover"
//             className="w-full border-4 border-purple-500 rounded-lg shadow-md"
//           />
//         </div>
//       )}
//     </div>
//   </div>
// );

// const Button = ({ onClick, disabled, loading, icon: Icon, children }) => (
//   <button
//     onClick={onClick}
//     disabled={disabled || loading}
//     className={`
//       w-full px-4 py-2 flex items-center justify-center
//       text-white rounded-md transition-colors duration-200
//       focus:outline-none focus:ring-2 focus:ring-offset-2
//       ${
//         disabled || loading
//           ? "bg-gray-400 cursor-not-allowed"
//           : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
//       }
//     `}
//   >
//     {loading ? (
//       <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
//         <circle
//           className="opacity-25"
//           cx="12"
//           cy="12"
//           r="10"
//           stroke="currentColor"
//           strokeWidth="4"
//         />
//         <path
//           className="opacity-75"
//           fill="currentColor"
//           d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//         />
//       </svg>
//     ) : (
//       Icon && <Icon className="w-5 h-5 mr-2" />
//     )}
//     {loading ? "Processing..." : children}
//   </button>
// );

// {selectedCover && (
//   <>
//     <TitleOptions
//       titleOptions={titleOptions}
//       selectedTitle={selectedTitle}
//       handleSelectTitle={setSelectedTitle}
//     />
//     <div className="flex justify-center my-8">
//       <Button
//         onClick={handleInsertTitleIntoCover}
//         disabled={
//           !selectedCover ||
//           !selectedTitle ||
//           isGeneratingCover ||
//           isGeneratingTitle
//         }
//         icon={PencilSquareIcon}
//       >
//         Insert Title into Cover
//       </Button>
//     </div>
//   </>
// )}

// {mergedImage && <MergedImage mergedImage={mergedImage} />}

{
  /* <LeftSidebar actions={sidebarActions} title="Cover Editor">
        <div className="space-y-4">
          <TitleInput 
            title={title} 
            onChange={(e) => handleTitleChange(e.target.value)} 
            onSave={handleTitleSave}
          />
          <Button
            onClick={handleGenerateTitleOptions}
            disabled={isGeneratingTitle || !title.trim()}
            loading={isGeneratingTitle}
            icon={PencilSquareIcon}
          >
            Generate Title Art
          </Button>

          <CoverPromptInput 
            coverImagePrompt={coverImagePrompt} 
            onChange={(e) => handleCoverImagePromptChange(e.target.value)} 
          />
          <Button
            onClick={handleGenerateCoverOptions}
            disabled={isGeneratingCover || !coverImagePrompt.trim() || !userPhotosRequestId}
            loading={isGeneratingCover}
            icon={PhotoIcon}
          >
            Generate Cover
          </Button>

          {uploadSection ? (
            <UploadPhotosComponent onUploadSuccess={handleUploadSuccess} />
          ) : (
            <ManagePhotosComponent
              refreshPhotos={refreshPhotos}
              onSelect={setUserPhotosRequestId}
              userPhotosRequestId={userPhotosRequestId}
              showDeleteButton={false}
              imageSize="large"
            />
          )}
        </div>
      </LeftSidebar> */
}

// import TitleOptions from "../components/TitleOptions";
// import MergedImage from "../components/MergedImage";
// const CoverTitleEditorPage = () => {
//   const {
//     title,
//     coverImagePrompt,
//     coverOptions,
//     titleOptions,
//     selectedCover,
//     selectedTitle,
//     mergedImage,
//     isGeneratingCover,
//     isGeneratingTitle,
//     defaultCover,
//     handleGenerateCoverOptions,
//     handleGenerateTitleOptions,
//     handleInsertTitleIntoCover,
//     handleContinueToBook,
//     handleTitleChange,
//     handleCoverImagePromptChange,
//     handleTitleSave,
//     handleSelectCover,
//     setSelectedTitle,
//     backgroundGenerationStatus,
//     bookId,
//     coverImages,
//     handleSetCover,
//   } = useStoryEditor();

// {uploadSection ? (
//   <UploadPhotosComponent onUploadSuccess={handleUploadSuccess} />
// ) : (

// )}

//     const [refreshPhotos, setRefreshPhotos] = useState(false);
// const handleUploadSuccess = (newPhotos) => {
//   setRefreshPhotos(!refreshPhotos);
//   setUploadSection(false);
//   setUserPhotosRequestId(newPhotos.requestId);
// };
// const [uploadSection, setUploadSection] = useState(false);
// const toggleUploadSection = () => {
//   setUploadSection(!uploadSection);
// };
// {
//   label: uploadSection ? "Select from Saved Photos" : "Upload New Photos",
//   onClick: toggleUploadSection,
//   icon: uploadSection ? PhotoIcon : ArrowUpOnSquareIcon,
//   className: 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500',
//   disabled: false,
// },

{
  /* <Button
            onClick={handleGenerateTitleOptions}
            disabled={isGeneratingTitle || !title.trim()}
            loading={isGeneratingTitle}
            icon={PencilSquareIcon}
          >
            Generate Title Art
          </Button> */
}
