// useStoryManager.js
import { useReducer, useCallback, useState } from 'react';
import { getStoryById, savePages, saveBook, generateStory, generateImagePrompts } from '../services/storyService';
import { compileStoryText } from "../utils/helpers";
import storyReducer, { initialState } from '../reducers/storyReducer';

const useStoryManager = () => {
  const [storyState, dispatch] = useReducer(storyReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const fetchStory = useCallback(async (id) => {
    setIsLoading(true);
    try {
      if (id) {
        const story = await getStoryById(id);
        const compiledStory = compileStoryText(story.pages);
        dispatch({
          type: 'SET_STORY_STATE',
          payload: {
            ...story,
            storyText: compiledStory,
            hasChanges: false,
          },
        });
      }
    } catch (error) {
      console.error('Error fetching story:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const reorderPages = useCallback((newPageOrder) => {
    const updatedPages = newPageOrder.map((page, index) => ({
      ...page,
      pageNumber: index + 1,
    }));

    dispatch({ 
      type: "REORDER_PAGES", 
      payload: updatedPages
    });
  }, [dispatch]);

  const deletePage = useCallback((pageNumber) => {
    dispatch({ type: "DELETE_PAGE", payload: pageNumber });
  }, [dispatch]);

  const saveCurrentStory = useCallback(async () => {
    try {
      const response = await savePages(storyState.bookId, storyState.pages);
      if (response.message === "Story saved successfully.") {
        dispatch({ type: "SET_HAS_CHANGES", payload: false });
        return true;
      }
    } catch (error) {
      console.error("Failed to save story:", error);
    }
    return false;
  }, [storyState]);

  const saveTitle = useCallback(async () => {
    try {
      const response = await saveBook(storyState.bookId, storyState.title);
      if (response.message === "Book saved successfully.") {
        dispatch({ type: "SET_HAS_CHANGES", payload: false });
        return true;
      }
    } catch (error) {
      console.error("Failed to save book title:", error);
    }
    return false;
  }, [storyState.bookId, storyState.title]);

  const setCurrentStoryId = useCallback((id) => {
    dispatch({ type: "SET_CURRENT_STORY_ID", payload: id });
    fetchStory(id);
  }, [dispatch, fetchStory]);

  const setStoryText = (newStoryText) => {
    dispatch({ type: "SET_STORY_TEXT", payload: newStoryText });
  };

  const setTitle = (newTitle) => {
    dispatch({ type: "SET_TITLE", payload: newTitle });
  };

  const resetStory = () => {
    dispatch({ type: "RESET_STORY" });
    localStorage.removeItem("storyData");
  };

  const addPage = () => {
    dispatch({ type: "ADD_PAGE" });
  };

  const updateStoryPage = (newText, pageNumber, blockIndex) => {
    const updatedStoryState = { ...storyState };
    const pageIndex = updatedStoryState.pages.findIndex(
      (p) => p.pageNumber === pageNumber
    );

    if (pageIndex !== -1) {
      updatedStoryState.pages[pageIndex].text_blocks[blockIndex].content = newText;
      dispatch({ type: "SET_STORY_STATE", payload: updatedStoryState });
    }
  };

  const generatingStory = async (settings, bookId) => {
    try {
      dispatch({
        type: "RESET_STORY",
      });
      const storyData = await generateStory(settings, bookId);
      setCurrentStoryId(storyData.bookId);
      dispatch({
        type: "SET_STORY_STATE",
        payload: storyData,
      });
  
      return storyData;
    } catch (error) {
      console.error("Failed to generate story:", error);
      throw error;
    }
  };

  const hasPrompts = useCallback(() => {
    console.log("CHECKING IF THERE ARE PROMPTS: ")
    if (isLoading || !storyState.pages || storyState.pages.length === 0) {
      console.log("RETURN TRUE IN HAS PROMPTS FIRST CONDITION")
      return true;
    }
    console.log("RETURNING SOME PAGES: ", storyState.pages.some(page => page.images && page.images.length > 0))
    return storyState.pages.some(page => page.images && page.images.length > 0);
  }, [storyState.pages, isLoading]);

  const generatePrompts = useCallback(async () => {
    console.log("Are we generating prompts again?")
    if (!hasPrompts()) {
      console.log("There are not prompts")
      try {
        const updatedBook = await generateImagePrompts(storyState);
        dispatch({
          type: "SET_STORY_STATE",
          payload: updatedBook,
        });
        return updatedBook;
      } catch (error) {
        console.error("Failed to generate image prompts:", error);
        throw error;
      }
    }
    return storyState;
  }, [storyState, dispatch, hasPrompts]);
  
  return {
    storyState,
    setCurrentStoryId,
    setStoryText,
    setTitle,
    resetStory,
    addPage,
    updateStoryPage,
    saveCurrentStory,
    saveTitle,
    generatingStory,
    dispatch,
    reorderPages,
    deletePage,
    generatePrompts,
    hasPrompts
  };
};

export default useStoryManager;


  // const generatingStory = async (settings, bookId) => {
  //   try {
  //     const storyData = await generateStory(settings, bookId);
  //     setCurrentStoryId(storyData.bookId);

  //     generateImagePrompts(storyData).then((book) => {
  //       console.log("BOOK WE GOT BACK: ", book)
  //       dispatch({
  //         type: "SET_STORY_STATE",
  //         payload: book,
  //       });
  //     });
  //     return storyData;
  //   } catch (error) {
  //     console.error("Failed to generate story:", error);
  //   }
  // };