import React from 'react';
import generatePDF from './generatePDF';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon, EyeIcon, ShoppingCartIcon, DocumentArrowDownIcon, StarIcon } from '@heroicons/react/24/solid';

const BuyModal = ({ isOpen, handleClose, book, onPreviewOpen }) => {
  const navigate = useNavigate();
  if (!isOpen || !book) return null;
  
  const handlePreviewBook = () => {
    onPreviewOpen(book);
  };

  const handlePurchase = () => {
    console.log("Purchase initiated for book:", book.id);
  };

  // const handleGeneratePDF = () => {
  //   generatePDF(book.pages, document.body);
  // };

  const handleViewPDF = () => {
    const restructuredBook = {
      pages: [
        {
          images: [
            {
              image_url: book.coverImages.find(img => img.is_default)?.imageUrl,
              isPlaced: true
            }
          ],
          text_blocks: [],
          width: 700,
          height: 700
        },
        ...book.pages.map((page) => ({
          images: page.images.filter(img => img.is_default).map(img => ({
            image_url: img.imageUrl,
            isPlaced: true
          })),
          text_blocks: page.text_blocks.map(block => ({
            id: block.id,
            content: block.content,
            block_data: {
              backgroundColor: block.backgroundColor + Math.round(block.opacity * 255).toString(16).padStart(2, "0"),
              fontFamily: block.fontFamily,
              fontSize: block.fontSize,
              textShadow: block.textShadow,
              fontWeight: block.fontWeight,
              fontStyle: block.fontStyle,
              textDecoration: block.textDecoration,
              width: block.width,
              height: block.height,
              textAlign: 'left',
              relativeX: block.relativeX,
              relativeY: block.relativeY,
              x: block.x,
              y: block.y
            },
            fontColor: block.color
          })),
          width: 700,
          height: 700
        }))
      ]
    };
    navigate('/book-overlay', { state: { book: restructuredBook } });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">{book.title}</h2>
          <button 
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close modal"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <img 
            src={book.cover} 
            alt={book.title} 
            className="w-full md:w-1/3 h-64 object-cover rounded-lg shadow-md"
          />
          
          <div className="flex-1">
            <div className="mb-4">
              <p className="text-sm text-gray-600 mb-1">Genre: {book.genre || 'N/A'}</p>
              <p className="text-sm text-gray-600 mb-1">Pages: {book.pageCount || 'N/A'}</p>
              <p className="text-sm text-gray-600 mb-1">Published: {book.publishDate || 'N/A'}</p>
              <p className="text-sm text-gray-600 mb-4">ISBN: {book.isbn || 'N/A'}</p>
              <p className="text-base text-gray-800">{book.description || 'No description available.'}</p>
            </div>
            
            <div className="flex justify-between items-center mb-4">
              <span className="text-2xl font-bold text-green-600">${book.price || '0.00'}</span>
              <div className="flex items-center">
                <span className="text-sm text-gray-600 mr-2">Rating:</span>
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <StarIcon 
                      key={i} 
                      className={`w-5 h-5 ${i < (book.rating || 0) ? 'text-yellow-400' : 'text-gray-300'}`} 
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <Button onClick={handlePreviewBook} icon={EyeIcon}>
            Preview Book
          </Button>
          <Button onClick={handlePurchase} icon={ShoppingCartIcon} color="green">
            Purchase
          </Button>
          <Button onClick={handleViewPDF} icon={DocumentArrowDownIcon} color="purple">
            Generate PDF
          </Button>
        </div>
      </div>
    </div>
  );
};

const Button = ({ onClick, icon: Icon, children, color = "blue" }) => (
  <button 
    onClick={onClick} 
    className={`flex items-center justify-center px-4 py-2 bg-${color}-500 text-white rounded-lg hover:bg-${color}-600 transition-colors focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-opacity-50`}
  >
    <Icon className="w-5 h-5 mr-2" />
    {children}
  </button>
);

export default BuyModal;

// const BuyModal = ({ isOpen, handleClose, book, onPreviewOpen }) => {
//   if (!isOpen || !book) return null;

//   const handlePreviewBook = () => {
//     onPreviewOpen(book);
//   };

//   const handlePurchase = () => {
//     console.log("Purchase initiated for book:", book.id);
//   };

//   const handleGeneratePDF = () => {
//     generatePDF(book.pages, document.body);
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-2xl font-bold text-gray-800">{book.title}</h2>
//           <button 
//             onClick={handleClose}
//             className="text-gray-600 hover:text-gray-800 transition-colors"
//           >
//             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//             </svg>
//           </button>
//         </div>
        
//         <img 
//           src={book.cover} 
//           alt={book.title} 
//           className="w-full h-64 object-cover rounded-lg mb-4 shadow-md"
//         />
        
//         <div className="mb-4">
//           <p className="text-sm text-gray-600 mb-2">Genre: {book.genre || 'N/A'}</p>
//           <p className="text-sm text-gray-600 mb-2">Pages: {book.pageCount || 'N/A'}</p>
//           <p className="text-sm text-gray-600 mb-2">Published: {book.publishDate || 'N/A'}</p>
//           <p className="text-sm text-gray-600 mb-4">ISBN: {book.isbn || 'N/A'}</p>
//           <p className="text-base text-gray-800">{book.description || 'No description available.'}</p>
//         </div>
        
//         <div className="flex justify-between items-center mb-4">
//           <span className="text-2xl font-bold text-green-600">${book.price || '0.00'}</span>
//           <div className="flex space-x-2">
//             <span className="text-sm text-gray-600">Rating:</span>
//             <div className="flex items-center">
//               {[...Array(5)].map((_, i) => (
//                 <svg key={i} className={`w-4 h-4 ${i < (book.rating || 0) ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//                 </svg>
//               ))}
//             </div>
//           </div>
//         </div>
        
//         <div className="flex space-x-4 mt-4">
//           <button 
//             onClick={handlePreviewBook} 
//             className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//           >
//             Preview Book
//           </button>
//           <button 
//             onClick={handlePurchase}
//             className="flex-1 px-4 py-2 bg-green-400 text-white rounded-lg hover:bg-green-600 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
//           >
//             Purchase
//           </button>
//           <button 
//             onClick={handleGeneratePDF}
//             className="flex-1 px-4 py-2 bg-purple-400 text-white rounded-lg hover:bg-purple-600 transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
//           >
//             Generate PDF
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BuyModal;