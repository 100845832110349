import React, {useEffect} from "react";
import BookCover from "./BookCover";
import Page from "./Page";
import Navigation from "./Navigation";

const Book = ({ story, isEditable, isGeneratingImages, viewMode, selectedImageIndex, onPageChange, currentPage }) => {
  useEffect(() => {
    if (currentPage === 0) {
      onPageChange(1);
    }
  }, []);

  const navigatePage = (newPage) => {
    if (newPage < 0 || newPage > story.pages.length + 1) return;
    
    let actualPage = newPage;
    if (viewMode === 'double' && newPage > 0 && newPage < story.pages.length) {
      actualPage = newPage % 2 === 0 ? newPage - 1 : newPage;
    }
    onPageChange(actualPage);
  };

  const goNextPage = () => {
    if (currentPage === 0) return 1;
    if (viewMode === 'double') {
      return Math.min(story.pages.length + 1, currentPage + 2);
    }
    return Math.min(story.pages.length + 1, currentPage + 1);
  };

  const goPreviousPage = () => {
    if (currentPage === 1) return 0;
    if (viewMode === 'double') {
      return Math.max(0, currentPage - 2);
    }
    return Math.max(1, currentPage - 1);
  };

  const isEndPage = currentPage > story.pages.length;
  const isLastContentPage = currentPage === story.pages.length;
  const frontCoverImage = story.coverImages.find(img => img.is_front_cover && img.is_default) || [];
  const backCoverImage = story.coverImages.find(img => !img.is_front_cover && img.is_default) || [];

  return (
    <div className="flex flex-col items-center max-w-[85vw] max-h-[90vh] max-w-4xl mx-auto">
    <div className="grow w-full overflow-hidden shadow-2xl rounded-lg">
      {currentPage === 0 ? (
        <BookCover
          isEditable={isEditable}
          coverImage={frontCoverImage}
          isGeneratingImages={isGeneratingImages}
          selectedImageIndex={selectedImageIndex}
        />
      ) : isEndPage ? (
        <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center w-full h-full">
          <BookCover
            isEditable={isEditable}
            coverImage={backCoverImage}
            isGeneratingImages={isGeneratingImages}
            selectedImageIndex={selectedImageIndex}
          />
        </div>
        ) : (
          <div className="flex flex-row h-full">
            <Page
              isEditable={isEditable}
              page={story.pages[currentPage - 1]}
              isGeneratingImages={isGeneratingImages}
              selectedImageIndex={selectedImageIndex}
            />
            {viewMode === 'double' && !isLastContentPage && (
              <Page
                isEditable={isEditable}
                page={story.pages[currentPage]}
                isGeneratingImages={isGeneratingImages}
                selectedImageIndex={selectedImageIndex}
              />
            )}
          </div>
        )}
      </div>
      <Navigation
        currentPageNum={currentPage}
        totalPages={story.pages.length + 1}
        onNavigate={navigatePage}
        getNextPage={goNextPage}
        getPreviousPage={goPreviousPage}
        viewMode={viewMode}
      />
    </div>
  );
};


// const Book = ({ story, isEditable, isGeneratingImages, viewMode, selectedImageIndex, onPageChange, currentPage }) => {
//   useEffect(() => {
//     if (currentPage === 0) {
//       onPageChange(1);
//     }
//   }, []);

//   const navigatePage = (newPage) => {
//     if (newPage < 0 || newPage > story.pages.length + 1) return;
    
//     let actualPage = newPage;
//     if (viewMode === 'double' && newPage > 0 && newPage < story.pages.length) {
//       actualPage = newPage % 2 === 0 ? newPage - 1 : newPage;
//     }
//     onPageChange(actualPage);
//   };

//   const goNextPage = () => {
//     if (currentPage === 0) return 1;
//     if (viewMode === 'double') {
//       return Math.min(story.pages.length + 1, currentPage + 2);
//     }
//     return Math.min(story.pages.length + 1, currentPage + 1);
//   };

//   const goPreviousPage = () => {
//     if (currentPage === 1) return 0;
//     if (viewMode === 'double') {
//       return Math.max(1, currentPage - 2);
//     }
//     return Math.max(0, currentPage - 1);
//   };

//   const TheEndPage = () => (
//     <div className="flex flex-col items-center justify-between w-full h-full bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-lg p-8">
//       <div className="flex flex-col items-center">
//         <BookOpenIcon className="w-32 h-32 text-blue-500 mb-6" />
//         <h2 className="text-4xl font-bold text-blue-800 mb-4">The End</h2>
//         <p className="text-xl text-blue-600 text-center max-w-md mb-8">
//           Thank you for reading this story. We hope you enjoyed the journey!
//         </p>
//       </div>
//       <div className="flex flex-col items-center">
//         <p className="text-lg text-blue-700 mb-4">
//           {story.title} by {story.author}
//         </p>
//         <button 
//           onClick={() => navigatePage(0)} 
//           className="flex items-center px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-200 text-lg font-semibold"
//         >
//           {/* <ArrowLeftIcon className="w-5 h-5 mr-2" /> */}
//           Back to Cover
//         </button>
//       </div>
//     </div>
//   );

//   // console.log("THE CURRENT PAGE: ", currentPage);
//   const isEndPage = currentPage > story.pages.length;
//   const isLastContentPage = currentPage === story.pages.length;
//   // console.log(story.pages[currentPage - 1])
//   return (
//     <div className="flex flex-col items-center max-w-[85vw] max-h-[90vh] max-w-4xl mx-auto">
//       <div className="grow w-full overflow-hidden shadow-2xl rounded-lg">
//         {currentPage === 0 ? (
//           <BookCover
//             isEditable={isEditable}
//             coverImages={story.coverImages}
//             isGeneratingImages={isGeneratingImages}
//             selectedImageIndex={selectedImageIndex}
//           />
//         ) : isEndPage ? (
//           <TheEndPage />
//         ) : (
//           <div className="flex flex-row h-full">
//             <Page
//               isEditable={isEditable}
//               page={story.pages[currentPage - 1]}
//               isGeneratingImages={isGeneratingImages}
//               selectedImageIndex={selectedImageIndex}
//             />
//             {viewMode === 'double' && !isLastContentPage && (
//               <Page
//                 isEditable={isEditable}
//                 page={story.pages[currentPage]}
//                 isGeneratingImages={isGeneratingImages}
//                 selectedImageIndex={selectedImageIndex}
//               />
//             )}
//           </div>
//         )}
//       </div>
//       <Navigation
//         currentPageNum={currentPage}
//         totalPages={story.pages.length + 1}
//         onNavigate={navigatePage}
//         getNextPage={goNextPage}
//         getPreviousPage={goPreviousPage}
//         viewMode={viewMode}
//       />
//     </div>
//   );
// };

export default Book;
