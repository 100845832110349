
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { loginUser, signupUser, forgotPassword } from '../services/user';
import { LockClosedIcon, EnvelopeIcon, UserIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { FormInput, ErrorMessage, SubmitButton } from "../components/AuthComponents";
// import { LoginPage } from "../components/AuthComponents";
{/* <LoginPage/> */}

const LoginPage = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      try {
        const response = await loginUser(formData);
        login(response.access_token, response.refresh_token, response.user_id);
        navigate('/home');
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <ErrorMessage message={error} />
            <FormInput id="email" name="email" type="email" value={formData.email} onChange={handleChange} label="Email address" icon={EnvelopeIcon} />
            <FormInput id="password" name="password" type="password" value={formData.password} onChange={handleChange} label="Password" icon={LockClosedIcon} />
            <SubmitButton loading={loading}>Sign in</SubmitButton>
          </form>
          <div className="flex items-center justify-between">
            <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
              Sign up
            </Link>
            <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    );
  };
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { loginUser } from '../services/user';

// const LoginPage = () => {
//   const navigate = useNavigate();
//   const { login } = useAuth();
//   const [formData, setFormData] = useState({ email: '', password: '' });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');
//     try {
//       const response = await loginUser(formData); // Assuming loginUser is an API call function
//       login(response.access_token, response.refresh_token, response.user_id);
//       navigate('/home'); // Redirect to home page after login
//     } catch (error) {
//       console.error(error);
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
//         <h2 className="text-2xl font-bold mb-6">Login</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label htmlFor="email" className="block text-gray-700">Email</label>
//             <input
//               id="email"
//               name="email"
//               type="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded mt-1"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label htmlFor="password" className="block text-gray-700">Password</label>
//             <input
//               id="password"
//               name="password"
//               type="password"
//               value={formData.password}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded mt-1"
//               required
//             />
//           </div>
//           {error && <div className="text-red-500 mb-4">{error}</div>}
//           <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//             Login
//           </button>
//         </form>
//         {loading && <div className="spinner"></div>}
//         <p className="mt-4">
//           Don't have an account?{' '}
//           <a href="/signup" className="text-blue-500">
//             Signup
//           </a>
//         </p>
//         <p>
//           <a href="/forgot-password" className="text-blue-500">
//             Forgot Password?
//           </a>
//         </p>
//       </div>
//     </div>
//   );
// };

export default LoginPage;
