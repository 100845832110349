import React, { useState } from 'react';
import { PhotoIcon, BoltIcon } from '@heroicons/react/24/outline';
import PhotoSelectionModal from './PhotoSelectionModal';

const QuickStartSection = ({ onWriteStory }) => {
  const [storyPrompt, setStoryPrompt] = useState("");
  const [settings, setSettings] = useState({
    userPhotosRequestId: null,
    imageLayout: "Single-page image",
    storyPrompt: "",
  });
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [showImageWarning, setShowImageWarning] = useState(false);

  const handleLayoutChange = (event) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      imageLayout: event.target.value,
    }));
  };

  const handlePromptChange = (event) => {
    const newPrompt = event.target.value;
    setStoryPrompt(newPrompt);
    setSettings((prevSettings) => ({
      ...prevSettings,
      storyPrompt: newPrompt,
    }));
  };

  const handlePhotoSelected = (requestId) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      userPhotosRequestId: requestId,
    }));
    setIsPhotoModalOpen(false);
    setShowImageWarning(false);
  };

  const handleGenerate = () => {
    if (!settings.userPhotosRequestId) {
      setShowImageWarning(true);
      setIsPhotoModalOpen(true);
    } else {
      onWriteStory(settings);
    }
  };

  return (
    <div className="p-4 border border-gray-200 rounded shadow">
      <h2 className="text-xl font-semibold mb-4">Start with a prompt</h2>
      <textarea
        value={storyPrompt}
        onChange={handlePromptChange}
        placeholder="Type your story prompt..."
        className="w-full p-2 mt-2 border rounded"
      />
      <div className="mt-4">
        <label className="block mb-2">
          <span className="text-gray-700">Image Layout</span>
          <div className="mt-1">
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                name="imageLayout"
                value="Single-page image"
                checked={settings.imageLayout === "Single-page image"}
                onChange={handleLayoutChange}
                className="form-radio"
              />
              <span className="ml-2">Single-page image</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="imageLayout"
                value="Double-page image"
                checked={settings.imageLayout === "Double-page image"}
                onChange={handleLayoutChange}
                className="form-radio"
              />
              <span className="ml-2">Double-page image</span>
            </label>
          </div>
        </label>
      </div>
      <div className="mt-4">
        <label className="block mb-2">
          <span className="text-gray-700">Select or Upload Photo <span className="text-red-500">*</span></span>
        </label>
        <button
          onClick={() => setIsPhotoModalOpen(true)}
          className="w-full p-2 bg-gray-100 text-gray-700 rounded flex items-center justify-center"
        >
          <PhotoIcon className="w-5 h-5 mr-2" />
          {settings.userPhotosRequestId ? "Change Photo" : "Select or Upload Photo"}
        </button>
        {showImageWarning && (
          <p className="text-red-500 mt-2">Please select an image before generating the story.</p>
        )}
      </div>
      <button
        onClick={handleGenerate}
        className="mt-4 w-full p-2 bg-blue-500 text-white rounded flex items-center justify-center"
      >
        <BoltIcon className="w-5 h-5 mr-2" />
        Generate
      </button>
      <PhotoSelectionModal 
        isOpen={isPhotoModalOpen}
        onClose={() => setIsPhotoModalOpen(false)}
        onPhotoSelected={handlePhotoSelected}
      />
    </div>
  );
};

export default QuickStartSection;
