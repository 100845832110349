import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF  from 'jspdf';
import html2canvas from 'html2canvas';
import { DocumentArrowDownIcon, PhotoIcon } from '@heroicons/react/24/outline';

const BookOverlay = () => {
  const bookRef = useRef(null);
  const location = useLocation();
  const { book } = location.state;
  const [processedBook, setProcessedBook] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  useEffect(() => {
    const processImages = async () => {
      const totalImages = book.pages.reduce(
        (count, page) => count + page.images.length,
        0
      );
      const processedPages = await Promise.all(
        book.pages.map(async (page) => {
          const processedImages = await Promise.all(
            page.images.map(async (img) => {
              try {
                const response = await fetch(img.image_url);
                const blob = await response.blob();
                const localUrl = URL.createObjectURL(blob);
                setLoadedImageCount((prev) => prev + 1);
                return { ...img, localUrl };
              } catch (error) {
                console.error("Failed to load image:", img.image_url, error);
                setLoadedImageCount((prev) => prev + 1);
                return img;
              }
            })
          );
          return { ...page, images: processedImages };
        })
      );

      setProcessedBook({ ...book, pages: processedPages });
      setIsLoading(false);
    };

    processImages();
  }, [book]);

  const calculateFontSize = (textBlock, containerWidth, containerHeight) => {
    const fontSizeOptions = {
      small: 0.015,
      medium: 0.02,
      large: 0.025,
      "extra-large": 0.03,
    };
    const baseSize = fontSizeOptions[textBlock.block_data.fontSize];
    const minFontSize = 12;
    const maxFontSize = 48;
    const calculatedSize = baseSize * containerWidth;
    return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
  };

  const generatePDFV1 = async () => {
    if (!processedBook) {
      console.log("Book is not processed yet");
      return;
    }

    setIsPdfGenerating(true);

    const pdf = new jsPDF("p", "in", [8.75, 8.75]);

    for (let i = 0; i < processedBook.pages.length; i++) {
      const pageElement = bookRef.current.querySelector(
        `.book-covers:nth-child(${i + 1})`
      );

      const canvas = await html2canvas(pageElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
      });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      if (i > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "JPEG", 0.125, 0.125, 8.5, 8.5);
    }

    pdf.save("bookv1.pdf");
    setIsPdfGenerating(false);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="text-2xl font-bold mb-4">Loading book...</div>
        <div className="w-64 h-6 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
            style={{ width: `${(loadedImageCount / book.pages.reduce((count, page) => count + page.images.length, 0)) * 100}%` }}
          ></div>
        </div>
        <div className="mt-2">
          {loadedImageCount} / {book.pages.reduce((count, page) => count + page.images.length, 0)} images loaded
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto">
        <button 
          onClick={generatePDFV1} 
          disabled={isLoading || isPdfGenerating}
          className={`mb-4 px-4 py-2 rounded-lg flex items-center ${
            isLoading || isPdfGenerating 
              ? 'bg-gray-300 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          <DocumentArrowDownIcon className="w-5 h-5 mr-2" />
          {isPdfGenerating ? 'Generating PDF...' : 'Export PDF'}
        </button>
        
        <div
          ref={bookRef}
          className="book-preview-overlay space-y-8"
        >
          {processedBook.pages.map((page, index) => (
            <div
              key={index}
              className="book-covers bg-white rounded-lg shadow-lg overflow-hidden"
              style={{ width: "700px", height: "700px", position: "relative" }}
            >
              <div className="image-area relative w-full h-full">
                {page.images.length > 0 && page.images[0].localUrl ? (
                  <img
                    src={page.images[0].localUrl}
                    alt={`Page ${index + 1}`}
                    className="generated-image w-full h-full object-cover"
                  />
                ) : (
                  <div className="flex items-center justify-center w-full h-full bg-gray-200">
                    <PhotoIcon className="w-24 h-24 text-gray-400" />
                  </div>
                )}
                <div className="absolute inset-0">
                  {page.text_blocks.map((textBlock) => {
                    const containerWidth = 700;
                    const containerHeight = 700;
                    const fontSize = calculateFontSize(
                      textBlock,
                      containerWidth,
                      containerHeight
                    );
                    const textBlockWidth =
                      textBlock.block_data.width * containerWidth;
                    const textBlockHeight =
                      textBlock.block_data.height * containerHeight;
                    const leftPosition = textBlock.block_data.x * containerWidth;
                    const topPosition = textBlock.block_data.y * containerHeight;

                    return (
                      <div
                        key={textBlock.id}
                        style={{
                          position: "absolute",
                          backgroundColor: textBlock.block_data.backgroundColor,
                          color: textBlock.fontColor,
                          fontSize: `${fontSize}px`,
                          textShadow: textBlock.block_data.textShadow,
                          fontWeight: textBlock.block_data.fontWeight,
                          fontStyle: textBlock.block_data.fontStyle,
                          textDecoration: textBlock.block_data.textDecoration,
                          width: `${textBlockWidth}px`,
                          height: `${textBlockHeight}px`,
                          left: `${leftPosition}px`,
                          top: `${topPosition}px`,
                        }}
                      >
                        {textBlock.content}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookOverlay;

// const BookOverlay = () => {
//   const bookRef = useRef(null);
//   const location = useLocation();
//   const { book } = location.state;
//   const [processedBook, setProcessedBook] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [loadedImageCount, setLoadedImageCount] = useState(0);

//   useEffect(() => {
//     const processImages = async () => {
//       const totalImages = book.pages.reduce(
//         (count, page) => count + page.images.length,
//         0
//       );
//       const processedPages = await Promise.all(
//         book.pages.map(async (page) => {
//           const processedImages = await Promise.all(
//             page.images.map(async (img) => {
//               try {
//                 const response = await fetch(img.image_url);
//                 const blob = await response.blob();
//                 const localUrl = URL.createObjectURL(blob);
//                 setLoadedImageCount((prev) => prev + 1);
//                 return { ...img, localUrl };
//               } catch (error) {
//                 console.error("Failed to load image:", img.image_url, error);
//                 setLoadedImageCount((prev) => prev + 1);
//                 return img;
//               }
//             })
//           );
//           return { ...page, images: processedImages };
//         })
//       );

//       setProcessedBook({ ...book, pages: processedPages });
//       setIsLoading(false);
//     };

//     processImages();
//   }, [book]);

//   const calculateFontSize = (textBlock, containerWidth, containerHeight) => {
//     const fontSizeOptions = {
//       small: 0.015,
//       medium: 0.02,
//       large: 0.025,
//       "extra-large": 0.03,
//     };
//     const baseSize = fontSizeOptions[textBlock.block_data.fontSize];
//     const minFontSize = 12;
//     const maxFontSize = 48;
//     const calculatedSize = baseSize * containerWidth;
//     return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
//   };

//   const generatePDFV1 = async () => {
//     if (!processedBook) {
//       console.log("Book is not processed yet");
//       return;
//     }

//     const pdf = new jsPDF("p", "in", [8.75, 8.75]);

//     for (let i = 0; i < processedBook.pages.length; i++) {
//       const pageElement = bookRef.current.querySelector(
//         `.book-covers:nth-child(${i + 1})`
//       );

//       const canvas = await html2canvas(pageElement, {
//         scale: 2,
//         useCORS: true,
//         allowTaint: true,
//         logging: true,
//       });

//       const imgData = canvas.toDataURL("image/jpeg", 1.0);

//       if (i > 0) {
//         pdf.addPage();
//       }

//       pdf.addImage(imgData, "JPEG", 0.125, 0.125, 8.5, 8.5);
//     }

//     pdf.save("bookv1.pdf");
//   };

//   if (isLoading) {
//     return (
//       <div>
//         Loading book... {loadedImageCount} /{" "}
//         {book.pages.reduce((count, page) => count + page.images.length, 0)}{" "}
//         images loaded
//       </div>
//     );
//   }

//   return (
//     <>
//       <button onClick={generatePDFV1} disabled={isLoading}>
//         Export PDF
//       </button>
//       <div
//         ref={bookRef}
//         style={{ display: "flex", flexDirection: "column", lineHeight: "1.25" }}
//         className="book-preview-overlay"
//       >
//         {processedBook.pages.map((page, index) => (
//           <div
//             key={index}
//             className="book-covers"
//             style={{ position: "relative", width: "700px", height: "700px" }}
//           >
//             <div className="image-area" style={{ position: "relative" }}>
//               {page.images.length > 0 && page.images[0].localUrl ? (
//                 <img
//                   src={page.images[0].localUrl}
//                   alt={`Page ${index + 1}`}
//                   className="generated-image inline-block"
//                   style={{
//                     width: "700px",
//                     height: "700px",
//                     objectFit: "cover",
//                   }}
//                 />
//               ) : (
//                 <img
//                   className="empty-image-icon"
//                   src={emptyImage}
//                   alt=""
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     objectFit: "contain",
//                   }}
//                 />
//               )}
//               <div
//                 style={{
//                   position: "absolute",
//                   top: 0,
//                   left: 0,
//                   width: "100%",
//                   height: "100%",
//                 }}
//               >
//                 {page.text_blocks.map((textBlock) => {
//                   const containerWidth = 700;
//                   const containerHeight = 700;
//                   const fontSize = calculateFontSize(
//                     textBlock,
//                     containerWidth,
//                     containerHeight
//                   );
//                   const textBlockWidth =
//                     textBlock.block_data.width * containerWidth;
//                   const textBlockHeight =
//                     textBlock.block_data.height * containerHeight;
//                   const leftPosition = textBlock.block_data.x * containerWidth;
//                   const topPosition = textBlock.block_data.y * containerHeight;

//                   return (
//                     <div
//                       key={textBlock.id}
//                       style={{
//                         position: "absolute",
//                         backgroundColor: textBlock.block_data.backgroundColor,
//                         color: textBlock.fontColor,
//                         fontSize: `${fontSize}px`,
//                         textShadow: textBlock.block_data.textShadow,
//                         fontWeight: textBlock.block_data.fontWeight,
//                         fontStyle: textBlock.block_data.fontStyle,
//                         textDecoration: textBlock.block_data.textDecoration,
//                         width: `${textBlockWidth}px`,
//                         height: `${textBlockHeight}px`,
//                         left: `${leftPosition}px`,
//                         top: `${topPosition}px`,
//                       }}
//                     >
//                       {textBlock.content}
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default BookOverlay;
