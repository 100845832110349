import { ExclamationTriangleIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
const AlertDialog = ({ isOpen, onClose, onConfirm, pageNumber }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex items-center mb-4">
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 mr-2" />
          <h3 className="text-lg font-semibold">Delete Page {pageNumber}?</h3>
        </div>
        <p className="mb-4 text-gray-600">
          This will delete the page and any associated images. This action
          cannot be undone.
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const TabButton = ({ active, onClick, label, className }) => (
    <button
      onClick={onClick}
      className={`p-3 flex-1 transition-colors duration-200 ${
        active
          ? "bg-blue-500 text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      } ${className}`}
    >
      {label}
    </button>
  );

  const QuickStartPrompt = ({ value, onChange, onRegenerate }) => (
    <div>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Type your story prompt..."
        className="w-full p-3 mt-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
        rows={5}
      />
      <button
        onClick={onRegenerate}
        className="mt-4 p-3 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
      >
        <ArrowPathIcon className="w-5 h-5 mr-2" />
        Regenerate Story
      </button>
    </div>
  );

export {AlertDialog, TabButton, QuickStartPrompt};
