import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  ArrowPathIcon,
  PlusIcon,
  ArrowRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useStory } from "../context/StoryContext";
import CollapsibleSidebar from "../components/CollapsibleSidebar";
import { TitleInput } from "../components/CoverComponents";
import TextEditor from "../components/TextEditor";
import TemplateCard from "../components/TemplateCard";
import LoadingOverlay from "../components/LoadingOverlay";
import {
  AlertDialog,
  TabButton,
  QuickStartPrompt,
} from "../components/ViewComponents";
import { clearExistingImages } from '../services/storyService';

const WriterPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    storyState,
    setCurrentStoryId,
    setTitle,
    addPage,
    updateStoryPage,
    saveCurrentStory,
    saveTitle,
    generatingStory,
    reorderPages,
    deletePage,
    generatePrompts,
    hasPrompts,
  } = useStory();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("quickStart");
  const [storyPrompt, setStoryPrompt] = useState("");
  const [useCurrentStory, setUseCurrentStory] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const hasGeneratedPromptsRef = useRef(false);

  useEffect(() => {
    if (id && id !== storyState.id) {
      setCurrentStoryId(id);
    }
    if (!hasPrompts() && !hasGeneratedPromptsRef.current) {
      hasGeneratedPromptsRef.current = true;
      generatePrompts().catch(error => {
        console.error("Failed to generate prompts:", error);
        hasGeneratedPromptsRef.current = false;
      });
    }
  }, [id, storyState.id, setCurrentStoryId, generatePrompts, hasPrompts, storyState.coverImages]);

  const handleSave = async () => {
    await saveCurrentStory();
    navigate(`/writer/${storyState.bookId}`);
  };

  const handleNextStep = async () => {
    navigate(`/cover-editor/${storyState.bookId}`);
  };

  const handleAddPage = () => {
    addPage();
  };

  const handleStoryChange = (newText, pageNumber, blockIndex) => {
    updateStoryPage(newText, pageNumber, blockIndex);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    await saveTitle();
  };

  const handleOpenRegenerateModal = () => {
    setIsRegenerateModalOpen(true);
    setActiveTab("quickStart");
  };

  const handleCloseRegenerateModal = () => {
    setIsRegenerateModalOpen(false);
  };

  const handleRegenerate = async (settings) => {
    setIsGenerating(true);
    handleCloseRegenerateModal();
    await clearExistingImages(storyState.bookId);
    try {
      const regenerationSettings = {
        ...settings,
        useCurrentStory,
        currentStoryContent: useCurrentStory ? storyState.pages : null,
      };

      if (activeTab === "quickStart") {
        await generatingStory(
          { ...regenerationSettings },
          storyState.bookId
        );
      } else {
        await generatingStory(regenerationSettings, storyState.bookId);
      }
    } catch (error) {
      console.error("Failed to regenerate story:", error);
    } finally {
      setIsGenerating(false);
      hasGeneratedPromptsRef.current = false;
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newPageOrder = Array.from(storyState.pages);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (storyState.settings.imageLayout === 'double') {
      // For double layout, move pairs of pages
      const [removed1, removed2] = newPageOrder.splice(sourceIndex * 2, 2);
      newPageOrder.splice(destinationIndex * 2, 0, removed1, removed2);
    } else {
      // For single layout, move individual pages
      const [removed] = newPageOrder.splice(sourceIndex, 1);
      newPageOrder.splice(destinationIndex, 0, removed);
    }

    reorderPages(newPageOrder);
  };

  const handleDeletePage = (pageNumber) => {
    if (storyState.pages.length > 1) {
      setPageToDelete(pageNumber);
      setIsAlertOpen(true);
    } else {
      alert("You cannot delete the last page of the story.");
    }
  };

  const confirmDeletePage = () => {
    if (pageToDelete) {
      deletePage(pageToDelete);
      setIsAlertOpen(false);
      setPageToDelete(null);
    }
  };

  const renderPages = () => {
    if (storyState.settings.imageLayout === 'double') {
      // Group pages in pairs for double layout
      const pairedPages = [];
      for (let i = 0; i < storyState.pages.length; i += 2) {
        pairedPages.push(storyState.pages.slice(i, i + 2));
      }

      return pairedPages.map((pagePair, index) => (
        <Draggable
          key={`pair-${pagePair[0].pageNumber}`}
          draggableId={`pair-${pagePair[0].pageNumber}`}
          index={index}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="mb-6 flex"
            >
              {pagePair.map((page) => (
                <div
                  key={page.pageNumber}
                  className="flex-1 relative bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 mr-2"
                >
                  <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-2 bg-gray-100 rounded-t-lg z-10">
                    <div className="text-gray-500 font-medium">
                      Page {page.pageNumber}
                    </div>
                    <button
                      onClick={() => handleDeletePage(page.pageNumber)}
                      className="p-1 text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-full transition-colors"
                      title="Delete Page"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="pt-10">
                    <TextEditor
                      textBlocks={page.text_blocks}
                      setStory={(newText, blockIndex) =>
                        handleStoryChange(
                          newText,
                          page.pageNumber,
                          blockIndex
                        )
                      }
                      pageNumber={page.pageNumber}
                      className="p-4"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Draggable>
      ));
    } else {
      // Render individual pages for single layout
      return storyState.pages.map((page, index) => (
        <Draggable
          key={page.pageNumber.toString()}
          draggableId={`page-${page.pageNumber}`}
          index={index}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="mb-6 relative bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
            >
              <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-2 bg-gray-100 rounded-t-lg z-10">
                <div className="text-gray-500 font-medium">
                  Page {page.pageNumber}
                </div>
                <button
                  onClick={() => handleDeletePage(page.pageNumber)}
                  className="p-1 text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-full transition-colors"
                  title="Delete Page"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="pt-10">
                <TextEditor
                  textBlocks={page.text_blocks}
                  setStory={(newText, blockIndex) =>
                    handleStoryChange(
                      newText,
                      page.pageNumber,
                      blockIndex
                    )
                  }
                  pageNumber={page.pageNumber}
                  className="p-4"
                />
              </div>
            </div>
          )}
        </Draggable>
      ));
    }
  };

  const sidebarActions = [
    {
      label: "Regenerate Story",
      onClick: handleOpenRegenerateModal,
      icon: ArrowPathIcon,
      className: "bg-purple-400 hover:bg-purple-600 focus:ring-purple-500",
      disabled: false,
    },
    {
      label: "Add Page",
      onClick: handleAddPage,
      icon: PlusIcon,
      className: "bg-blue-400 hover:bg-blue-600 focus:ring-blue-500",
      disabled: false,
    },
    {
      label: "Next Step",
      onClick: handleNextStep,
      icon: ArrowRightIcon,
      className: "bg-green-400 hover:bg-green-600 focus:ring-green-500",
      disabled: false,
    },
  ];

  return (
    <div className="flex w-full h-screen bg-gray-50 overflow-hidden">
      <CollapsibleSidebar actions={sidebarActions} title="Story Editor">
        <TitleInput
          title={storyState.title}
          onChange={handleTitleChange}
          onSave={handleTitleSave}
          className="mb-4 p-3 bg-white rounded-lg shadow-sm"
        />
      </CollapsibleSidebar>
      <div className="flex-1 overflow-y-auto">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="pages">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="p-6 relative w-full"
              >
                {renderPages()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex justify-end mt-4 pr-6 pb-6">
          <button
            onClick={handleNextStep}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            Next: Cover Editor
            <ArrowRightIcon className="w-5 w-5 ml-2" />
          </button>
        </div>
      </div>
      {isRegenerateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] flex flex-col">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-2xl font-semibold text-gray-800">
                Regenerate Story
              </h2>
              <button
                onClick={handleCloseRegenerateModal}
                className="p-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="flex-grow overflow-y-auto p-6">
              <div className="flex mb-6">
                <TabButton
                  active={activeTab === "quickStart"}
                  onClick={() => setActiveTab("quickStart")}
                  label="Prompt"
                  className="rounded-l-lg"
                />
                <TabButton
                  active={activeTab === "settings"}
                  onClick={() => setActiveTab("settings")}
                  label="Edit Settings"
                  className="rounded-r-lg"
                />
              </div>
              <div className="mb-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={useCurrentStory}
                    onChange={(e) => setUseCurrentStory(e.target.checked)}
                    className="mr-2"
                  />
                  <span>Use current story content</span>
                </label>
              </div>
              <div className="mt-6">
                {activeTab === "settings" ? (
                  <TemplateCard
                    onWriteStory={handleRegenerate}
                    initialSettings={storyState.settings}
                    renderAsModal={false}
                  />
                ) : (
                  <QuickStartPrompt
                    value={storyPrompt}
                    onChange={setStoryPrompt}
                    onRegenerate={() => handleRegenerate({ ...storyState.settings, storyPrompt })}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isGenerating && <LoadingOverlay />}
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onConfirm={confirmDeletePage}
        pageNumber={pageToDelete}
      />
    </div>
  );
};

export default WriterPage;
// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// import {
//   ArrowPathIcon,
//   PlusIcon,
//   ArrowRightIcon,
//   XMarkIcon,
// } from "@heroicons/react/24/solid";
// import { useStory } from "../context/StoryContext";
// import CollapsibleSidebar from "../components/CollapsibleSidebar";
// import { TitleInput } from "../components/CoverComponents";
// import TextEditor from "../components/TextEditor";
// import TemplateCard from "../components/TemplateCard";
// import LoadingOverlay from "../components/LoadingOverlay";
// import {
//   AlertDialog,
//   TabButton,
//   QuickStartPrompt,
// } from "../components/ViewComponents";
// import { clearExistingImages } from '../services/storyService';

// const WriterPage = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const {
//     storyState,
//     setCurrentStoryId,
//     setTitle,
//     addPage,
//     updateStoryPage,
//     saveCurrentStory,
//     saveTitle,
//     generatingStory,
//     reorderPages,
//     deletePage,
//     generatePrompts,
//     hasPrompts,
//   } = useStory();
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
//   const [activeTab, setActiveTab] = useState("quickStart");
//   const [storyPrompt, setStoryPrompt] = useState("");
//   const [useCurrentStory, setUseCurrentStory] = useState(false);
//   const [isAlertOpen, setIsAlertOpen] = useState(false);
//   const [pageToDelete, setPageToDelete] = useState(null);
//   const hasGeneratedPromptsRef = useRef(false);
//   useEffect(() => {
//     if (id && id !== storyState.id) {
//       setCurrentStoryId(id);
//     }
//     console.log("USE EFFECT CALLED OR? ")
//     if (!hasPrompts() && !hasGeneratedPromptsRef.current) {
//       hasGeneratedPromptsRef.current = true;
//       generatePrompts().catch(error => {
//         console.error("Failed to generate prompts:", error);
//         hasGeneratedPromptsRef.current = false; 
//       });
//     }
//   }, [id, storyState.id, setCurrentStoryId, generatePrompts, hasPrompts, storyState.coverImages]);

//   const handleSave = async () => {
//     await saveCurrentStory();
//     navigate(`/writer/${storyState.bookId}`);
//   };

//   const handleNextStep = async () => {
//     // await handleSave();
//     navigate(`/cover-editor/${storyState.bookId}`);
//   };

//   const handleAddPage = () => {
//     addPage();
//   };

//   const handleStoryChange = (newText, pageNumber, blockIndex) => {
//     updateStoryPage(newText, pageNumber, blockIndex);
//   };

//   const handleTitleChange = (e) => {
//     setTitle(e.target.value);
//   };

//   const handleTitleSave = async () => {
//     await saveTitle();
//   };

//   const handleOpenRegenerateModal = () => {
//     setIsRegenerateModalOpen(true);
//     setActiveTab("quickStart");
//   };

//   const handleCloseRegenerateModal = () => {
//     setIsRegenerateModalOpen(false);
//   };

//   const handleRegenerate = async (settings) => {
//     console.log("THE SETTINGS: ", settings)
//     console.log("Current settings: ", storyState.settings)
//     setIsGenerating(true);
//     handleCloseRegenerateModal();
//     await clearExistingImages(storyState.bookId)
//     try {
//       const regenerationSettings = {
//         ...settings,
//         useCurrentStory,
//         currentStoryContent: useCurrentStory ? storyState.pages : null,
//       };

//       if (activeTab === "quickStart") {
//          await generatingStory(
//           { ...regenerationSettings },
//           storyState.bookId
//         );
//       } else {
//         await generatingStory(regenerationSettings, storyState.bookId);
//       }
//     } catch (error) {
//       console.error("Failed to regenerate story:", error);
//     } finally {
//       setIsGenerating(false);
//       hasGeneratedPromptsRef.current = false;
//     }
//   }
//   const handleDragEnd = (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const newPageOrder = Array.from(storyState.pages);
//     const [reorderedPage] = newPageOrder.splice(result.source.index, 1);
//     newPageOrder.splice(result.destination.index, 0, reorderedPage);

//     reorderPages(newPageOrder);
//   };

//   const handleDeletePage = (pageNumber) => {
//     if (storyState.pages.length > 1) {
//       setPageToDelete(pageNumber);
//       setIsAlertOpen(true);
//     } else {
//       alert("You cannot delete the last page of the story.");
//     }
//   };

//   const confirmDeletePage = () => {
//     if (pageToDelete) {
//       deletePage(pageToDelete);
//       setIsAlertOpen(false);
//       setPageToDelete(null);
//     }
//   };

//   const sidebarActions = [
//     {
//       label: "Regenerate Story",
//       onClick: handleOpenRegenerateModal,
//       icon: ArrowPathIcon,
//       className: "bg-purple-400 hover:bg-purple-600 focus:ring-purple-500",
//       disabled: false,
//     },
//     {
//       label: "Add Page",
//       onClick: handleAddPage,
//       icon: PlusIcon,
//       className: "bg-blue-400 hover:bg-blue-600 focus:ring-blue-500",
//       disabled: false,
//     },
//     {
//       label: "Next Step",
//       onClick: handleNextStep,
//       icon: ArrowRightIcon,
//       className: "bg-green-400 hover:bg-green-600 focus:ring-green-500",
//       disabled: false,
//     },
//   ];
//   return (
//     <div className="flex w-full h-screen bg-gray-50 overflow-hidden">
//       <CollapsibleSidebar actions={sidebarActions} title="Story Editor">
//         <TitleInput
//           title={storyState.title}
//           onChange={handleTitleChange}
//           onSave={handleTitleSave}
//           className="mb-4 p-3 bg-white rounded-lg shadow-sm"
//         />
//       </CollapsibleSidebar>
//       <div className="flex-1 overflow-y-auto">
//         <DragDropContext onDragEnd={handleDragEnd}>
//           <Droppable droppableId="pages">
//             {(provided) => (
//               <div
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//                 className="p-6 relative w-full"
//               >
//                 {storyState.pages.map((page, index) => (
//                   <Draggable
//                     key={page.pageNumber.toString()}
//                     draggableId={`page-${page.pageNumber}`}
//                     index={index}
//                   >
//                     {(provided) => (
//                       <div
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         className="mb-6 relative bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
//                       >
//                         <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-2 bg-gray-100 rounded-t-lg z-10">
//                           <div className="text-gray-500 font-medium">
//                             Page {page.pageNumber}
//                           </div>
//                           <button
//                             onClick={() => handleDeletePage(page.pageNumber)}
//                             className="p-1 text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-full transition-colors"
//                             title="Delete Page"
//                           >
//                             <XMarkIcon className="h-5 w-5" />
//                           </button>
//                         </div>
//                         <div className="pt-10">
//                           <TextEditor
//                             textBlocks={page.text_blocks}
//                             setStory={(newText, blockIndex) =>
//                               handleStoryChange(
//                                 newText,
//                                 page.pageNumber,
//                                 blockIndex
//                               )
//                             }
//                             pageNumber={page.pageNumber}
//                             className="p-4"
//                           />
//                         </div>
//                       </div>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         </DragDropContext>
//         <div className="flex justify-end mt-4 pr-6 pb-6">
//           <button
//             onClick={handleNextStep}
//             className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
//           >
//             Next: Cover Editor
//             <ArrowRightIcon className="w-5 w-5 ml-2" />
//           </button>
//         </div>
//       </div>
//       {isRegenerateModalOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
//           <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] flex flex-col">
//             <div className="flex justify-between items-center p-6 border-b">
//               <h2 className="text-2xl font-semibold text-gray-800">
//                 Regenerate Story
//               </h2>
//               <button
//                 onClick={handleCloseRegenerateModal}
//                 className="p-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
//               >
//                 <XMarkIcon className="h-6 w-6" />
//               </button>
//             </div>
//             <div className="flex-grow overflow-y-auto p-6">
//               <div className="flex mb-6">
//                 <TabButton
//                   active={activeTab === "quickStart"}
//                   onClick={() => setActiveTab("quickStart")}
//                   label="Prompt"
//                   className="rounded-l-lg"
//                 />
//                 <TabButton
//                   active={activeTab === "settings"}
//                   onClick={() => setActiveTab("settings")}
//                   label="Edit Settings"
//                   className="rounded-r-lg"
//                 />
//               </div>
//               <div className="mb-4">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={useCurrentStory}
//                     onChange={(e) => setUseCurrentStory(e.target.checked)}
//                     className="mr-2"
//                   />
//                   <span>Use current story content</span>
//                 </label>
//               </div>
//               <div className="mt-6">
//                 {activeTab === "settings" ? (
//                   <TemplateCard
//                     onWriteStory={handleRegenerate}
//                     initialSettings={storyState.settings}
//                     renderAsModal={false}
//                   />
//                 ) : (
//                   <QuickStartPrompt
//                     value={storyPrompt}
//                     onChange={setStoryPrompt}
//                     onRegenerate={() => handleRegenerate({ ...storyState.settings, storyPrompt })}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {isGenerating && <LoadingOverlay />}
//       <AlertDialog
//         isOpen={isAlertOpen}
//         onClose={() => setIsAlertOpen(false)}
//         onConfirm={confirmDeletePage}
//         pageNumber={pageToDelete}
//       />
//     </div>
//   );
// };

// export default WriterPage;

  // return (
  //   <div className="flex w-full h-screen bg-gray-50">
  //     <CollapsibleSidebar actions={sidebarActions} title="Story Editor">
  //       <TitleInput
  //         title={storyState.title}
  //         onChange={handleTitleChange}
  //         onSave={handleTitleSave}
  //         className="mb-4 p-3 bg-white rounded-lg shadow-sm"
  //       />
  //     </CollapsibleSidebar>
  //     <DragDropContext onDragEnd={handleDragEnd}>
  //       <Droppable droppableId="pages">
  //         {(provided) => (
  //           <div
  //             {...provided.droppableProps}
  //             ref={provided.innerRef}
  //             className="grow p-6 relative w-full h-full overflow-auto"
  //           >
  //             {storyState.pages.map((page, index) => (
  //               <Draggable
  //                 key={page.pageNumber.toString()}
  //                 draggableId={`page-${page.pageNumber}`}
  //                 index={index}
  //               >
  //                 {(provided) => (
  //                   <div
  //                     ref={provided.innerRef}
  //                     {...provided.draggableProps}
  //                     {...provided.dragHandleProps}
  //                     className="mb-6 relative bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
  //                   >
  //                     <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-2 bg-gray-100 rounded-t-lg z-10">
  //                       <div className="text-gray-500 font-medium">
  //                         Page {page.pageNumber}
  //                       </div>
  //                       <button
  //                         onClick={() => handleDeletePage(page.pageNumber)}
  //                         className="p-1 text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-full transition-colors"
  //                         title="Delete Page"
  //                       >
  //                         <XMarkIcon className="h-5 w-5" />
  //                       </button>
  //                     </div>
  //                     <div className="pt-10">
  //                       <TextEditor
  //                         textBlocks={page.text_blocks}
  //                         setStory={(newText, blockIndex) =>
  //                           handleStoryChange(
  //                             newText,
  //                             page.pageNumber,
  //                             blockIndex
  //                           )
  //                         }
  //                         pageNumber={page.pageNumber}
  //                         className="p-4"
  //                       />
  //                     </div>
  //                   </div>
  //                 )}
  //               </Draggable>
  //             ))}
  //             <div className="flex justify-end mt-4 pr-6">
  //               <button
  //                 onClick={handleNextStep}
  //                 className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center"
  //               >
  //                 Next: Cover Editor
  //                 <ArrowRightIcon className="w-5 h-5 ml-2" />
  //               </button>
  //             </div>
  //             {provided.placeholder}
  //           </div>
  //         )}
  //       </Droppable>
  //     </DragDropContext>
  //     {isRegenerateModalOpen && (
  //       <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
  //         <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] flex flex-col">
  //           <div className="flex justify-between items-center p-6 border-b">
  //             <h2 className="text-2xl font-semibold text-gray-800">
  //               Regenerate Story
  //             </h2>
  //             <button
  //               onClick={handleCloseRegenerateModal}
  //               className="p-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
  //             >
  //               <XMarkIcon className="h-6 w-6" />
  //             </button>
  //           </div>
  //           <div className="flex-grow overflow-y-auto p-6">
  //             <div className="flex mb-6">
  //               <TabButton
  //                 active={activeTab === "quickStart"}
  //                 onClick={() => setActiveTab("quickStart")}
  //                 label="Prompt"
  //                 className="rounded-l-lg"
  //               />
  //               <TabButton
  //                 active={activeTab === "settings"}
  //                 onClick={() => setActiveTab("settings")}
  //                 label="Edit Settings"
  //                 className="rounded-r-lg"
  //               />
  //             </div>
  //             <div className="mb-4">
  //               <label className="flex items-center">
  //                 <input
  //                   type="checkbox"
  //                   checked={useCurrentStory}
  //                   onChange={(e) => setUseCurrentStory(e.target.checked)}
  //                   className="mr-2"
  //                 />
  //                 <span>Use current story content</span>
  //               </label>
  //             </div>
  //             <div className="mt-6">
  //               {activeTab === "settings" ? (
  //                 <TemplateCard
  //                   onWriteStory={handleRegenerate}
  //                   initialSettings={storyState.settings}
  //                   renderAsModal={false}
  //                 />
  //               ) : (
  //                 <QuickStartPrompt
  //                   value={storyPrompt}
  //                   onChange={setStoryPrompt}
  //                   onRegenerate={() => handleRegenerate({ ...storyState.settings,storyPrompt })}
  //                 />
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //     {isGenerating && <LoadingOverlay />}
  //     <AlertDialog
  //       isOpen={isAlertOpen}
  //       onClose={() => setIsAlertOpen(false)}
  //       onConfirm={confirmDeletePage}
  //       pageNumber={pageToDelete}
  //     />
  //   </div>
  // );

  // const handleRegenerate = async (settings) => {
  //   setIsGenerating(true);
  //   handleCloseRegenerateModal();
  //   try {
  //     const regenerationSettings = {
  //       ...settings,
  //       useCurrentStory,
  //       currentStoryContent: useCurrentStory ? storyState.pages : null,
  //     };

  //     if (activeTab === "quickStart") {
  //       await generatingStory(
  //         { ...regenerationSettings, storyPrompt },
  //         storyState.bookId
  //       );
  //     } else {
  //       await generatingStory(regenerationSettings, storyState.bookId);
  //     }
  //   } catch (error) {
  //     console.error("Failed to regenerate story:", error);
  //   } finally {
  //     setIsGenerating(false);
  //   }
  // };