import React, { useState, useEffect } from 'react';
import { useAuth } from "../context/AuthContext";
import { getPhotos, deletePhotoPack } from "../services/photoService";
import { useStory } from "../context/StoryContext";
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/24/outline';

const ManagePhotosComponent = ({
  refreshPhotos,
  onSelect,
  userPhotosRequestId,
  showDeleteButton = true,
}) => {
  const { user } = useAuth();
  const [photos, setPhotos] = useState([]);
  const { dispatch, storyState } = useStory();
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await getPhotos(user.id);
        setPhotos(response);
        if (storyState.settings.requestId) {
          console.log("HERE BUDY")
          handleSelect({ requestId: storyState.settings.requestId });
        }
      } catch (error) {
        console.error("Failed to fetch photos:", error);
      }
    };

    fetchPhotos();
  }, [user.id, refreshPhotos, storyState.settings.requestId, userPhotosRequestId]);

  const handleDelete = async (requestId) => {
    if (window.confirm('Are you sure you want to delete this photo pack?')) {
      try {
        await deletePhotoPack(requestId);
        setPhotos(photos.filter((photo) => photo.requestId !== requestId));
      } catch (error) {
        console.error("Failed to delete photo pack:", error);
      }
    }
  };

  const handleSelect = (photo) => {
    console.log("Selected photo:", photo.requestId);
    if(!onSelect)return;
    onSelect(photo.requestId);
    dispatch({
      type: "SET_USER_PHOTOS_REQUEST_ID",
      payload: photo.requestId,
    });
  };
  
  return (
    <div className="space-y-6">
      {photos.length === 0 ? (
        <p className="text-center text-gray-600 py-4 bg-gray-100 rounded-lg">Upload photos from your profile.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {photos.map((photo) => (
            <div
              key={photo.requestId}
              className={`relative border rounded-lg shadow-md overflow-hidden transition-all duration-300 ${
                userPhotosRequestId === photo.requestId ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              <div className={`aspect-w-3 aspect-h-4`}>
                <img
                  src={photo.frontalPhotoUrl}
                  alt={"Frontal"}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center space-y-2">
                {onSelect && (
                  <button
                    type="button"
                    onClick={() => handleSelect(photo)}
                    className="p-2 bg-green-400 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
                    aria-label="Select photo"
                  >
                    <CheckCircleIcon className="w-6 h-6" />
                  </button>
                )}
                {showDeleteButton && (
                  <button
                    type="button"
                    onClick={() => handleDelete(photo.requestId)}
                    className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-300"
                    aria-label="Delete photo"
                  >
                    <TrashIcon className="w-6 h-6" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManagePhotosComponent;
