import React from 'react';
import { Link } from 'react-router-dom';
import { PencilSquareIcon, BookOpenIcon, PaintBrushIcon, TruckIcon } from '@heroicons/react/24/outline';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Hero />
      <HowItWorks />
      <Testimonials />
      <FinalCTA />
    </div>
  );
};

const Hero = () => (
  <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
    <div className="container mx-auto px-4 py-20 text-center">
      <h1 className="text-4xl md:text-5xl font-extrabold mb-4">Create Personalized Story Gifts</h1>
      <p className="text-xl mb-8">Give the Gift of a Custom Tale Starring Your Loved Ones</p>
      <Link to="/login" className="bg-white text-blue-600 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Create your own
      </Link>
    </div>
  </div>
);

const HowItWorks = () => {
  const features = [
    { icon: PencilSquareIcon, title: "Personalize Your Story", description: "Begin by inputting details about your child, choose a character, or use a template. Customize settings and preferences to guide the creation of a story that's as unique as your child." },
    { icon: BookOpenIcon, title: "Create Your Book", description: "Our platform uses advanced AI to weave your details into a personalized narrative. Watch as a unique storybook filled with enchanting tales and adventures comes to life." },
    { icon: PaintBrushIcon, title: "Edit & Illustrate", description: "Refine the text with AI-assisted editing tools and regenerate images to fit your story perfectly. Customize illustrations and text to ensure every page resonates with your child." },
    { icon: TruckIcon, title: "Print & Ship", description: "Finalize your story and choose to get it professionally printed. Create a beautiful, tangible book that can be cherished and shared. Ship it directly to your doorstep as the perfect keepsake or gift." },
  ];

  return (
    <div className="bg-gray-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition duration-300 hover:shadow-xl">
    <Icon className="h-12 w-12 text-blue-500 mb-4 mx-auto" />
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    { quote: "We've seen a significant improvement in our child's creativity and storytelling skills!", author: "Jordan Lee", role: "Parent" },
    { quote: "The custom storybooks were a hit at my son's birthday party. Highly recommended!", author: "Sam Rivera", role: "Happy Dad" },
    { quote: "This platform brought so much joy to our family storytelling time. It's incredibly user-friendly.", author: "Alex Kim", role: "Educator" },
  ];

  return (
    <div className="bg-blue-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">What Our Users Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ quote, author, role }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition duration-300 hover:shadow-xl">
    <p className="italic text-gray-600 mb-4">"{quote}"</p>
    <h4 className="font-bold">{author}</h4>
    <p className="text-blue-500">{role}</p>
  </div>
);

const FinalCTA = () => (
  <div className="bg-gradient-to-r from-purple-600 to-blue-500 text-white py-20">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-4">Ready to Create Lasting Memories?</h2>
      <p className="text-xl mb-8">Your child's personalized adventure awaits. Craft a unique storybook that will be treasured for years to come.</p>
      <Link to="/signup" className="bg-white text-blue-600 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Start Your Story
      </Link>
    </div>
  </div>
);

export default LandingPage;
// const LandingPage = () => {
//   return (
//     <div className="landing-page">
//       <div id="header" className="bg-cover bg-center hero h-64 text-center flex flex-col items-center justify-center text-white p-8">
//         <h1 className="text-4xl font-bold">Create Personalized Story Gifts</h1>
//         <p className="mt-4">Give the Gift of a Custom Tale Starring Your Loved Ones</p>
//         <div className="mt-6">
//           <a href="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//             Create your own
//           </a>
//         </div>
//       </div>

//       <div id="how-it-works" className="how-it-works p-8">
//         <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
//           <div className="feature-card p-6 bg-white rounded-lg shadow-md text-center">
//             <div className="feature-icon mb-4">
//               <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" viewBox="0 0 512 512"></svg>
//             </div>
//             <h3 className="text-xl font-bold mb-2">Personalize Your Story</h3>
//             <p>
//               Begin by inputting details about your child, choose a character,
//               or use a template. Customize settings and preferences to guide the
//               creation of a story that's as unique as your child.
//             </p>
//           </div>
//           <div className="feature-card p-6 bg-white rounded-lg shadow-md text-center">
//             <div className="feature-icon mb-4">
//               <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" viewBox="0 0 512 512"></svg>
//             </div>
//             <h3 className="text-xl font-bold mb-2">Create Your Book</h3>
//             <p>
//               Our platform uses advanced AI to weave your details into a
//               personalized narrative. Watch as a unique storybook filled with
//               enchanting tales and adventures comes to life.
//             </p>
//           </div>
//           <div className="feature-card p-6 bg-white rounded-lg shadow-md text-center">
//             <div className="feature-icon mb-4">
//               <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" viewBox="0 0 512 512"></svg>
//             </div>
//             <h3 className="text-xl font-bold mb-2">Edit & Illustrate</h3>
//             <p>
//               Refine the text with AI-assisted editing tools and regenerate
//               images to fit your story perfectly. Customize illustrations and
//               text to ensure every page resonates with your child.
//             </p>
//           </div>
//           <div className="feature-card p-6 bg-white rounded-lg shadow-md text-center">
//             <div className="feature-icon mb-4">
//               <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" viewBox="0 0 512 512"></svg>
//             </div>
//             <h3 className="text-xl font-bold mb-2">Print & Ship</h3>
//             <p>
//               Finalize your story and choose to get it professionally printed.
//               Create a beautiful, tangible book that can be cherished and
//               shared. Ship it directly to your doorstep as the perfect keepsake
//               or gift.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div id="testimonials-container" className="testimonials-container p-8 bg-gray-100">
//         <h2 className="text-3xl font-bold text-center mb-8">What Our Users Say</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           <div className="testimonial-card p-6 bg-white rounded-lg shadow-md text-center">
//             <p className="italic">
//               <span className="quote-icon text-4xl">&ldquo;</span>
//               We've seen a significant improvement in our child's creativity and storytelling skills!
//               <span className="quote-icon text-4xl">&rdquo;</span>
//             </p>
//             <h4 className="mt-4 text-xl font-bold">Jordan Lee</h4>
//             <p className="text-gray-600">Parent</p>
//           </div>
//           <div className="testimonial-card p-6 bg-white rounded-lg shadow-md text-center">
//             <p className="italic">
//               <span className="quote-icon text-4xl">&ldquo;</span>
//               The custom storybooks were a hit at my son's birthday party. Highly recommended!
//               <span className="quote-icon text-4xl">&rdquo;</span>
//             </p>
//             <h4 className="mt-4 text-xl font-bold">Sam Rivera</h4>
//             <p className="text-gray-600">Happy Dad</p>
//           </div>
//           <div className="testimonial-card p-6 bg-white rounded-lg shadow-md text-center">
//             <p className="italic">
//               <span className="quote-icon text-4xl">&ldquo;</span>
//               This platform brought so much joy to our family storytelling time. It's incredibly user-friendly.
//               <span className="quote-icon text-4xl">&rdquo;</span>
//             </p>
//             <h4 className="mt-4 text-xl font-bold">Alex Kim</h4>
//             <p className="text-gray-600">Educator</p>
//           </div>
//         </div>
//       </div>

//       <div className="final-cta text-center p-8">
//         <h2 className="text-3xl font-bold mb-4">Ready to Create Lasting Memories?</h2>
//         <p className="mb-6">Your child’s personalized adventure awaits. Craft a unique storybook that will be treasured for years to come.</p>
//         <a href="/Signup" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//           Start Your Story
//         </a>
//       </div>
//     </div>
//   );
// };

// export default LandingPage;
