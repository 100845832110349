import React from 'react';

const TextEditor = ({ textBlocks, setStory }) => {
  return (
    <div className="relative w-full p-4 border border-gray-200 rounded mb-4">
      {textBlocks.map((block, blockIndex) => (
        <textarea
          key={blockIndex}
          className="w-full h-full p-4 border-gray-200 rounded mb-2"
          placeholder="Start writing your story..."
          value={block.content}
          onChange={(e) => setStory(e.target.value, blockIndex)}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      ))}
    </div>
  );
};

export default TextEditor;




// const TextEditor = ({ story, setStory, handleHighlightedTextChange }) => {
//   const handleMouseUp = () => {
//     const selection = window.getSelection();
//     const selectedText = selection.toString();
//     const cursorPosition = selection.anchorOffset;
//     handleHighlightedTextChange(selectedText, cursorPosition);
//   };

//   return (
//     <textarea
//       className="w-1/2 p-4 border border-gray-200 rounded"
//       placeholder="Start writing your story..."
//       value={story}
//       onChange={(e) => setStory(e.target.value)}
//       onMouseUp={handleMouseUp}
//       style={{ whiteSpace: 'pre-wrap' }}
//     />
//   );
// };

// export default TextEditor;


// import React, { useState } from 'react';

// const TextEditor = ({ story, setStory, setHighlightedText }) => {
//   const handleMouseUp = () => {
//     const selectedText = window.getSelection().toString();
//     setHighlightedText(selectedText);
//   };

//   return (
//     <textarea
//       className="w-full h-full p-4 border border-gray-200 rounded"
//       placeholder="Start writing your story..."
//       value={story}
//       onChange={(e) => setStory(e.target.value)}
//       onMouseUp={handleMouseUp}
//       style={{ whiteSpace: 'pre-wrap' }}
//     />
//   );
// };

// export default TextEditor;
