import React, { useState } from 'react';
import { CheckIcon, PencilIcon, StarIcon, PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

const CoverOptions = ({ coverOptions = [], selectedCover, handleSelectCover, handleSetCover, frontCover, backCover }) => (
  <div className="mb-8">
    <h3 className="text-2xl font-bold mb-4 text-gray-800">Select Cover Image</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {coverOptions.map((cover, index) => (
        <div key={index} className="relative group">
          {cover.imageUrl ? (
            <>
              <img
                src={cover.imageUrl}
                alt={`Cover option ${index + 1}`}
                className={`w-full h-auto cursor-pointer rounded-lg transition-all duration-200 ${
                  selectedCover === cover ? "ring-4 ring-blue-500" : "group-hover:opacity-75"
                }`}
                onClick={() => handleSelectCover(cover)}
              />
              <div className="absolute top-2 right-2 flex space-x-2">
                {selectedCover === cover && (
                  <div className="bg-blue-500 text-white rounded-full p-1">
                    <CheckIcon className="h-6 w-6" />
                  </div>
                )}
              </div>
              <div className="absolute bottom-2 left-2 right-2 flex justify-between">
                <button
                  onClick={() => handleSetCover(cover, true)}
                  className={`bg-green-500 text-white rounded-md px-2 py-1 text-sm hover:bg-green-600 transition-colors duration-200 ${
                    frontCover === cover ? 'ring-2 ring-green-600' : ''
                  }`}
                >
                  {frontCover === cover ? 'Front Cover' : 'Set as Front'}
                </button>
                <button
                  onClick={() => handleSetCover(cover, false)}
                  className={`bg-purple-500 text-white rounded-md px-2 py-1 text-sm hover:bg-purple-600 transition-colors duration-200 ${
                    backCover === cover ? 'ring-2 ring-purple-600' : ''
                  }`}
                >
                  {backCover === cover ? 'Back Cover' : 'Set as Back'}
                </button>
              </div>
            </>
          ) : (
            <div className="w-full h-64 flex items-center justify-center bg-gray-200 rounded-lg">
              <span className="text-gray-500">Generate your first cover image</span>
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

const TitleInput = ({ title, onChange, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    onSave();
  };

  return (
    <div className="mb-6">
      {/* <label htmlFor="coverPrompt" className="block text-sm font-medium text-gray-700 mb-2">Cover Image Prompt</label> */}
      <p className="block text-sm font-medium text-gray-500 mb-2">Book Title</p>
      <div className="flex items-center">
        {isEditing ? (
          <>
            <input
              id="title"
              type="text"
              value={title}
              onChange={onChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="Enter book title"
            />
            <button 
              onClick={handleSaveClick} 
              className="ml-2 p-2 text-white bg-green-400 rounded-full hover:bg-green-600 transition-colors duration-200"
            >
              <CheckIcon className="h-5 w-5" />
            </button>
          </>
        ) : (
          <>
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <button 
              onClick={handleEditClick} 
              className="ml-2 p-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              <PencilIcon className="h-5 w-5" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const MergedImage = ({ mergedImage }) => (
  <div className="mb-8">
    <h3 className="text-2xl font-bold mb-4 text-gray-800">Generated Merged Image</h3>
    <img
      src={mergedImage.imageUrl}
      alt="Generated cover with title"
      className="w-full max-w-md mx-auto rounded-lg shadow-md"
    />
  </div>
);

const TitleOptions = ({ titleOptions, selectedTitle, handleSelectTitle }) => (
  <div className="mb-8">
    <h3 className="text-2xl font-bold mb-4 text-gray-800">Select Title Image</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {titleOptions.map((titleImage, index) => (
        <div key={index} className="relative group">
          <img
            src={titleImage.imageUrl}
            alt={`Title option ${index + 1}`}
            className={`w-full h-auto cursor-pointer rounded-lg transition-all duration-200 ${
              selectedTitle === titleImage ? "ring-4 ring-purple-500" : "group-hover:opacity-75"
            }`}
            onClick={() => handleSelectTitle(titleImage)}
          />
          {selectedTitle === titleImage && (
            <div className="absolute top-2 right-2 bg-purple-400 text-white rounded-full p-1">
              <CheckIcon className="h-6 w-6" />
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

export { CoverOptions, MergedImage, TitleOptions, TitleInput };


// const CoverOptions = ({ coverOptions = [], selectedCover, handleSelectCover }) => (
//   <div className="mb-8">
//     <h3 className="text-2xl font-bold mb-4 text-gray-800">Select Cover Image</h3>
//     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
//       {coverOptions.map((cover, index) => (
//         <div key={index} className="relative group">
//           {cover.imageUrl ? (
//             <img
//               src={cover.imageUrl}
//               alt={`Cover option ${index + 1}`}
//               className={`w-full h-auto cursor-pointer rounded-lg transition-all duration-200 ${
//                 selectedCover === cover ? "ring-4 ring-blue-500" : "group-hover:opacity-75"
//               }`}
//               onClick={() => handleSelectCover(cover)}
//             />
//           ) : (
//             <div className="w-full h-64 flex items-center justify-center bg-gray-200 rounded-lg">
//               <span className="text-gray-500">Generate your first cover image</span>
//             </div>
//           )}
//           {selectedCover === cover && (
//             <div className="absolute top-2 right-2 bg-blue-500 text-white rounded-full p-1">
//               <CheckIcon className="h-6 w-6" />
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   </div>
// );