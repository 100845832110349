import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const Navigation = ({
  currentPageNum,
  totalPages,
  onNavigate,
  getNextPage,
  getPreviousPage,
}) => {
  const goToPreviousPage = () => {
    if (currentPageNum > 0) {
      onNavigate(getPreviousPage(currentPageNum));
    }
  };

  const goToNextPage = () => {
    if (currentPageNum < totalPages) {
      onNavigate(getNextPage(currentPageNum));
    }
  };

  return (
    <div className="flex items-center justify-around w-full mt-4 px-4">
      <button
        onClick={goToPreviousPage}
        disabled={currentPageNum <= 0}
        className={`p-2 rounded-full ${
          currentPageNum <= 0
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-blue-500 text-white hover:bg-blue-600"
        } transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
        aria-label="Previous page"
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <div className="text-gray-700 font-medium">
        {currentPageNum > totalPages - 1
          ? "Back Cover"
          : `Page ${currentPageNum === 0 ? "Cover" : currentPageNum} of ${
              totalPages - 1
            }`}
      </div>
      <button
        onClick={goToNextPage}
        disabled={currentPageNum > totalPages - 1}
        className={`p-2 rounded-full ${
          currentPageNum > totalPages - 1
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-blue-500 text-white hover:bg-blue-600"
        } transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
        aria-label="Next page"
      >
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default Navigation;
