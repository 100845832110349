import React, { useState, useEffect, useCallback } from "react";
import { PhotoIcon, ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Alert, AlertDescription } from "./ui/alert";

const CoverSelector = ({
  coverOptions,
  onSelectCover,
  onGenerateCovers,
  isGenerating,
  initialFrontCover,
  initialBackCover,
}) => {
  const [frontCover, setFrontCover] = useState(initialFrontCover);
  const [backCover, setBackCover] = useState(initialBackCover);
  const [enlargedCover, setEnlargedCover] = useState(null);

  useEffect(() => {
    setFrontCover(initialFrontCover);
    setBackCover(initialBackCover);
  }, [initialFrontCover, initialBackCover]);

  const handleSelectCover = (cover, isFront) => {
    if (isFront) {
      setFrontCover(cover);
    } else {
      setBackCover(cover);
    }
    onSelectCover(cover, isFront);
  };

  const handleShowEnlarged = useCallback((cover) => {
    console.log("SHOWING ENLARGED COVER: ", cover);
    setEnlargedCover(cover);
  }, []);

  const handleCloseEnlarged = useCallback(() => {
    setEnlargedCover(null);
  }, []);

  const CoverImage = ({ cover, alt, className }) => (
    <div className="relative">
      <img
        src={cover.imageUrl}
        alt={alt}
        className={`w-full object-cover rounded-md cursor-pointer ${className}`}
        onClick={() => handleShowEnlarged(cover)}
      />
    </div>
  );

  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Cover Selection</h2>
        <Button onClick={onGenerateCovers} disabled={isGenerating}>
          {isGenerating ? (
            <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <PhotoIcon className="mr-2 h-4 w-4" />
          )}
          Generate Covers
        </Button>
      </div>

      {coverOptions.length === 0 && (
        <Alert className="mb-4">
          <AlertDescription>
            No cover options available. Click 'Generate Covers' to create some!
          </AlertDescription>
        </Alert>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <Card>
          <CardHeader>
            <CardTitle>Front Cover</CardTitle>
          </CardHeader>
          <CardContent>
            {frontCover ? (
              <CoverImage
                cover={frontCover}
                alt="Front Cover"
                className="h-64"
              />
            ) : (
              <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-md">
                <span className="text-gray-500">No front cover selected</span>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Back Cover</CardTitle>
          </CardHeader>
          <CardContent>
            {backCover ? (
              <CoverImage cover={backCover} alt="Back Cover" className="h-64" />
            ) : (
              <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-md">
                <span className="text-gray-500">No back cover selected</span>
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {coverOptions.map((cover, index) => (
          <Card key={cover.imageId || index}>
            <CardContent className="pt-4">
              <CoverImage
                cover={cover}
                alt={`Cover option ${index + 1}`}
                className="h-40"
              />
            </CardContent>
            <CardFooter className="p-2 flex justify-between">
              <Button
                size="sm"
                onClick={() => handleSelectCover(cover, true)}
                variant={frontCover === cover ? "default" : "outline"}
              >
                Front
              </Button>
              <Button
                size="sm"
                onClick={() => handleSelectCover(cover, false)}
                variant={backCover === cover ? "default" : "outline"}
              >
                Back
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>

      {enlargedCover && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50 p-4">
          <div className="relative w-full max-w-3xl max-h-[80vh] bg-white rounded-lg overflow-hidden">
            <img
              src={enlargedCover.imageUrl}
              alt="Enlarged Cover"
              className="w-full h-full object-contain"
            />
            <Button
              className="absolute top-2 right-2 bg-white bg-opacity-75 hover:bg-opacity-100"
              size="icon"
              onClick={handleCloseEnlarged}
            >
              <XMarkIcon className="h-6 w-6" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoverSelector;
