import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ForgotPassword from "./pages/ForgotPassword";
import ProfilePage from "./pages/ProfilePage";
import HomePage from "./pages/HomePage";
import WriterPage from "./pages/WriterPage";
import BookPage from "./pages/BookPage";
import { AuthProvider } from "./context/AuthContext";
import { StoryProvider } from "./context/StoryContext";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ProtectedRoute from "./utils/ProtectedRoute";
import CoverTitleEditorPage from "./pages/CoverTitleEditorPage";
import BookOverlay from "./components/BookOverlay";

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AuthProvider>
        
        <Router>
        <StoryProvider>
          <Navbar />
          <div className="grow flex flex-col">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/writer/:id"
                element={
                  <ProtectedRoute>
                      <WriterPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/book/:id"
                element={
                  <ProtectedRoute>
                      <BookPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cover-editor/:id"
                element={
                  <ProtectedRoute>
                      <CoverTitleEditorPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                      <ProfilePage />
                  </ProtectedRoute>
                }
              />
               <Route path="/book-overlay" element={<BookOverlay />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </div>
          <Footer />
          </StoryProvider>
        </Router>
        
      </AuthProvider>
    </div>
  );
};
// const App = () => {
//   return (
//     <div className="flex flex-col min-h-screen">
//       <AuthProvider>
//         <Router>
//           <Navbar />
//           <div className="grow flex flex-col">
//             <Routes>
//               <Route path="/" element={<LandingPage />} />
//               <Route path="/login" element={<LoginPage />} />
//               <Route path="/signup" element={<SignupPage />} />
//               <Route
//                 path="/home"
//                 element={
//                   <ProtectedRoute>
//                     <HomePage />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/writer/:id"
//                 element={
//                   <ProtectedRoute>
//                     <StoryProvider>
//                       <WriterPage />
//                     </StoryProvider>
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/book/:id"
//                 element={
//                   <ProtectedRoute>
//                     <StoryProvider>
//                       <BookPage />
//                     </StoryProvider>
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/cover-editor/:id"
//                 element={
//                   <ProtectedRoute>
//                     <StoryProvider>
//                       <CoverTitleEditorPage />
//                     </StoryProvider>
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/profile"
//                 element={
//                   <ProtectedRoute>
//                     <StoryProvider>
//                       <ProfilePage />
//                     </StoryProvider>
//                   </ProtectedRoute>
//                 }
//               />
//                <Route path="/book-overlay" element={<BookOverlay />} />
//               <Route path="/forgot-password" element={<ForgotPassword />} />
//               <Route path="/contact" element={<ContactPage />} />
//               <Route path="/about" element={<AboutPage />} />
//             </Routes>
//           </div>
//           <Footer />
//         </Router>
//       </AuthProvider>
//     </div>
//   );
// };

export default App;
