import React, { useState } from 'react';

const TitleInput = ({ title, onChange, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    onSave();
  };

  return (
    <div className="mb-6">
      <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-2">
        Book Title
      </label>
      <div className="flex items-center">
        {isEditing ? (
          <>
            <input
              id="title"
              type="text"
              value={title}
              onChange={onChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter book title"
            />
            <button onClick={handleSaveClick} className="ml-2 text-green-500">
              ✔️
            </button>
          </>
        ) : (
          <>
            <h2 className="text-lg font-medium">{title || 'Untitled Book'}</h2>
            <button onClick={handleEditClick} className="ml-2 text-gray-500">
              ✏️
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TitleInput;
// import React, { useState } from 'react';

// const TitleInput = ({ title, onChange, onSave }) => {
//   const [isEditing, setIsEditing] = useState(false);

//   const handleEditClick = () => setIsEditing(true);
//   const handleSaveClick = () => {
//     setIsEditing(false);
//     onSave();
//   };

//   return (
//     <div className="mb-6 flex items-center">
//       {isEditing ? (
//         <>
//           <input
//             id="title"
//             type="text"
//             value={title}
//             onChange={onChange}
//             className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
//             placeholder="Enter book title"
//           />
//           <button onClick={handleSaveClick} className="ml-2 text-green-500">
//             ✔️
//           </button>
//         </>
//       ) : (
//         <>
//           <h2 className="text-lg font-medium">{title}</h2>
//           <button onClick={handleEditClick} className="ml-2 text-gray-500">
//             ✏️
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default TitleInput;

// import React from 'react';

// const TitleInput = ({ title, onChange }) => (
//   <div className="mb-6">
//     <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-2">Book Title</label>
//     <input
//       id="title"
//       type="text"
//       value={title}
//       onChange={onChange}
//       className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
//       placeholder="Enter book title"
//     />
//   </div>
// );

// export default TitleInput;
