import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import PremadeBookCard from "../components/PremadeBookCard";
import BookPreviewModal from "../components/BookPreviewModal";
import UploadPhotosComponent from "../components/UploadPhotosComponent";
import ManagePhotosComponent from "../components/ManagePhotosComponent";
import { getSavedStories, getStoryById } from "../services/storyService";
import { BookOpenIcon, PhotoIcon, CogIcon } from "@heroicons/react/24/outline";

const ProfilePage = () => {
  const { user } = useAuth();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshPhotos, setRefreshPhotos] = useState(false);
  const [previewBook, setPreviewBook] = useState(null);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        setLoading(true);
        const savedStories = await getSavedStories();
        setBooks(savedStories);
      } catch (error) {
        console.error("Failed to fetch saved stories:", error);
      } finally {
        setLoading(false);
      }
    };
    if (user) {
      fetchBooks();
    }
  }, [user]);

  const handleUploadSuccess = () => {
    setRefreshPhotos(!refreshPhotos);
  };

  const handlePreviewOpen = (book) => {
    setPreviewBook(book);
  };

  const handlePreviewClose = () => {
    setPreviewBook(null);
  };

  const handleDelete = (bookId) => {
    console.log("BOOK ID: ", bookId);
    setBooks((prevBooks) => prevBooks.filter((book) => book.bookId !== bookId));
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      {previewBook && (
        <BookPreviewModal
          isOpen={!!previewBook}
          handleClose={handlePreviewClose}
          book={previewBook}
        />
      )}
      <h1 className="text-4xl font-bold mb-8 text-gray-800">My Profile</h1>
      <div className="space-y-12">
        <Section title="My Books" icon={BookOpenIcon}>
          {loading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {[...Array(4)].map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-gray-200 h-64 rounded-lg shadow-md"
                ></div>
              ))}
            </div>
          ) : books.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {books.map((book) => (
                <PremadeBookCard
                  key={book.id}
                  bookId={book.id}
                  onPreviewOpen={handlePreviewOpen}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-600 text-center py-8 bg-gray-100 rounded-lg">
              You haven't created any books yet.
            </p>
          )}
        </Section>

        <Section title="Upload Photos" icon={PhotoIcon}>
          <UploadPhotosComponent onUploadSuccess={handleUploadSuccess} />
        </Section>

        <Section title="Manage Photos" icon={CogIcon}>
          <ManagePhotosComponent
            refreshPhotos={refreshPhotos}
            isProfilePage={true}
          />
        </Section>
      </div>
    </div>
  );
};

const Section = ({ title, icon: Icon, children }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="flex items-center mb-6">
      <Icon className="w-6 h-6 text-blue-500 mr-2" />
      <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
    </div>
    {children}
  </div>
);

export default ProfilePage;
