import React from 'react';

const CoverPromptInput = ({ coverImagePrompt, onChange }) => {
  return(
  <div className="mb-6">
    <label htmlFor="coverPrompt" className="block text-sm font-medium text-gray-700 mb-2">Cover Image Prompt</label>
    <textarea
      id="coverPrompt"
      value={coverImagePrompt}
      onChange={onChange}
      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      placeholder="Enter cover image prompt"
      rows="3"
    />
  </div>
);
}

export default CoverPromptInput;
