import React, { useRef, useEffect } from "react";
export const FontFamilySelect = ({
    fontFamilies,
    selectedFontFamily,
    onFontFamilyChange,
  }) => (
    <select
      value={selectedFontFamily}
      onChange={(e) => onFontFamilyChange(e.target.value)}
    >
      {fontFamilies.map((style) => (
        <option key={style} value={style}>
          {style}
        </option>
      ))}
    </select>
  );
  
  export const FontSizeSelect = ({
    fontSizeOptions,
    selectedFontSize,
    onFontSizeChange,
  }) => (
    <select
      value={selectedFontSize}
      onChange={(e) => onFontSizeChange(e.target.value)}
    >
      {fontSizeOptions.map((size) => (
        <option key={size} value={size}>
          {size}
        </option>
      ))}
    </select>
  );
  
  export const ColorPicker = ({ selectedColor, onColorChange }) => (
    <input
      type="color"
      value={selectedColor}
      onChange={(e) => onColorChange(e.target.value)}
    />
  );
  
  export const TextShadowSelect = ({
    textShadowOptions,
    selectedTextShadow,
    onTextShadowChange,
  }) => (
    <select
      value={selectedTextShadow}
      onChange={(e) => onTextShadowChange(e.target.value)}
    >
      {textShadowOptions.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
  
  export const StyleToggle = ({ label, isActive, onToggle }) => (
    <label>
      <input type="checkbox" checked={isActive} onChange={onToggle} /> {label}
    </label>
  );
  
  export const TextAlignSelect = ({
    textAlignOptions,
    selectedTextAlign,
    onTextAlignChange,
  }) => (
    <select
      value={selectedTextAlign}
      onChange={(e) => onTextAlignChange(e.target.value)}
    >
      {textAlignOptions.map((align) => (
        <option key={align} value={align}>
          {align}
        </option>
      ))}
    </select>
  );
  
  export const ResizeHandle = ({ onResizeStart }) => {
    const buttonRef = useRef(null);
  
    useEffect(() => {
      const button = buttonRef.current;
      if (button) {
        const handleEvent = (e) => {
          e.preventDefault();
          onResizeStart(e);
        };
  
        button.addEventListener('mousedown', handleEvent, { passive: false });
        button.addEventListener('touchstart', handleEvent, { passive: false });
  
        return () => {
          button.removeEventListener('mousedown', handleEvent);
          button.removeEventListener('touchstart', handleEvent);
        };
      }
    }, [onResizeStart]);
  
    return (
      <button
        ref={buttonRef}
        className="resize-handle"
        style={{
          position: "absolute",
          display: "inline",
          right: 0,
          width: "20px",
          height: "20px",
          cursor: "nwse-resize",
          touchAction: "none",
        }}
      >
        ⇔
      </button>
    );
  };
  
  export const BackgroundStyleControls = ({
    selectedBackdropFilter,
    selectedBackgroundColor,
    selectedOpacity,
    onBackgroundStyleChange,
  }) => {
    return (
      <div>
        <select
          value={
            selectedBackdropFilter === "none"
              ? "none"
              : selectedBackdropFilter?.replace(/\D/g, "")
          }
          onChange={(e) =>
            onBackgroundStyleChange("backdropFilter", e.target.value)
          }
        >
          <option value="none">No Blur</option>
          <option value="5">Blur 5px</option>
          <option value="10">Blur 10px</option>
          <option value="15">Blur 15px</option>
        </select>
        <input
          type="color"
          value={selectedBackgroundColor}
          onChange={(e) => {
            console.log("color change", e.target.value)
            onBackgroundStyleChange("backgroundColor", e.target.value)
          }}
        />
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={selectedOpacity}
          onChange={(e) => {
            console.log("opacity change", e.target.value)
            onBackgroundStyleChange("opacity", parseFloat(e.target.value))
          }}
        />
      </div>
    );
  };
  
  export const BackgroundStyleSelect = ({
    selectedType,
    selectedColor,
    selectedOpacity,
    onBackgroundStyleChange,
  }) => {
    return (
      <div>
        <select
          value={selectedType}
          onChange={(e) => onBackgroundStyleChange("type", e.target.value)}
        >
          <option value="none">No Background</option>
          <option value="blur">Blur</option>
          <option value="solid">Solid Color</option>
          <option value="color">Colored with Opacity</option>
        </select>
        {selectedType !== "none" && selectedType !== "blur" && (
          <input
            type="color"
            value={selectedColor}
            onChange={(e) => onBackgroundStyleChange("color", e.target.value)}
          />
        )}
        {selectedType === "color" && (
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={selectedOpacity}
            onChange={(e) =>
              onBackgroundStyleChange("opacity", parseFloat(e.target.value))
            }
          />
        )}
      </div>
    );
  };