import { ForgotPassword } from "../components/AuthComponents";
<ForgotPassword/>
export default ForgotPassword;
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { forgotPassword } from '../services/user';

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const navigate = useNavigate();

//   const handleChange = (e) => setEmail(e.target.value);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await forgotPassword(email);
//       alert('Password reset email sent. Please check your inbox.');
//       navigate('/login'); // Redirect back to login after request
//     } catch (error) {
//       console.error(error);
//       alert('Error sending password reset email. Please try again later.');
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
//         <h2 className="text-2xl font-bold mb-6">Forgot Password</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label htmlFor="email" className="block text-gray-700">Email</label>
//             <input
//               id="email"
//               name="email"
//               type="email"
//               value={email}
//               onChange={handleChange}
//               className="w-full p-2 border border-gray-300 rounded mt-1"
//               required
//             />
//           </div>
//           <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//             Send Reset Email
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;
