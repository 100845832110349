import React, { useState, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { uploadPhotos } from "../services/photoService";
import { PhotoIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import Cropper from "react-easy-crop";

const ALLOWED_ASPECT_RATIOS = [
  { name: "1:1", value: 1 },
  { name: "3:4", value: 3 / 4 },
  { name: "2:3", value: 2 / 3 },
  { name: "9:16", value: 9 / 16 },
  { name: "1:2", value: 1 / 2 },
];

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, "image/png");
  });
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const UploadPhotosComponent = ({ onUploadSuccess }) => {
  const { user } = useAuth();
  const [frontalPhoto, setFrontalPhoto] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [frontalPhotoPreview, setFrontalPhotoPreview] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(
    ALLOWED_ASPECT_RATIOS[0]
  );
  const [activeCropPhoto, setActiveCropPhoto] = useState(null);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const frontalInputRef = useRef(null);
  const profileInputRef = useRef(null);

  const handlePhotoChange = (e, photoType) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("File size exceeds 5 MB limit. Please choose a smaller file.");
        return;
      }
      const localUrl = URL.createObjectURL(file);
      setSelectedPhoto({ url: localUrl, file: file });
      setActiveCropPhoto(photoType);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (frontalPhoto && profilePhoto && age && gender) {
      setIsUploading(true);
      try {
        const response = await uploadPhotos(user.id, frontalPhoto, profilePhoto, age, gender);
        setFrontalPhoto(null);
        setProfilePhoto(null);
        setFrontalPhotoPreview(null);
        setProfilePhotoPreview(null);
        setAge("");
        setGender("");
        if (frontalInputRef.current) frontalInputRef.current.value = "";
        if (profileInputRef.current) profileInputRef.current.value = "";
        onUploadSuccess(response);
      } catch (error) {
        console.error("Failed to upload photos:", error);
      } finally {
        setIsUploading(false);
      }
    } else {
      alert("Please fill out all fields and select both photos before submitting.");
    }
  };

  
  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedPhoto.url,
        croppedAreaPixels
      );
      const blob = await fetch(croppedImage).then((res) => res.blob());
      const localUrl = URL.createObjectURL(blob);
      if (activeCropPhoto === "frontal") {
        setFrontalPhoto(blob);
        setFrontalPhotoPreview(localUrl);
      } else {
        setProfilePhoto(blob);
        setProfilePhotoPreview(localUrl);
      }
    } catch (error) {
      console.error("Error cropping image:", error);
    }
    setSelectedPhoto(null);
    setActiveCropPhoto(null);
  };

  const handleUseAsIs = () => {
    if (activeCropPhoto === "frontal") {
      setFrontalPhoto(selectedPhoto.file);
      setFrontalPhotoPreview(selectedPhoto.url);
    } else {
      setProfilePhoto(selectedPhoto.file);
      setProfilePhotoPreview(selectedPhoto.url);
    }
    setSelectedPhoto(null);
    setActiveCropPhoto(null);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FileUpload
            label="Frontal Photo"
            onChange={(e) => handlePhotoChange(e, "frontal")}
            preview={frontalPhotoPreview}
            inputRef={frontalInputRef}
          />
          <FileUpload
            label="Profile Photo"
            onChange={(e) => handlePhotoChange(e, "profile")}
            preview={profilePhotoPreview}
            inputRef={profileInputRef}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block mb-2 font-semibold text-gray-700">
              Age
            </label>

            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label className="block mb-2 font-semibold text-gray-700">
              Gender
            </label>

            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            >
              <option value="" disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          className={`w-full p-3 rounded-lg text-white font-semibold flex items-center justify-center ${
            isUploading || !frontalPhoto || !profilePhoto || !age || !gender
              ? "bg-blue-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          } transition-colors duration-300`}
          disabled={
            isUploading || !frontalPhoto || !profilePhoto || !age || !gender
          }
        >
          {isUploading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : (
            <>
              <CloudArrowUpIcon className="w-5 h-5 mr-2" />
              Submit
            </>
          )}
        </button>
      </form>
      {selectedPhoto && (
        <CropModal
          selectedPhoto={selectedPhoto}
          crop={crop}
          zoom={zoom}
          onCropComplete={onCropComplete}
          setCrop={setCrop}
          setZoom={setZoom}
          handleCrop={handleCrop}
          handleUseAsIs={handleUseAsIs}
          setSelectedPhoto={setSelectedPhoto}
          selectedAspectRatio={selectedAspectRatio}
          setSelectedAspectRatio={setSelectedAspectRatio}
        />
      )}
    </div>
  );
};

const CropModal = ({
  selectedPhoto,
  crop,
  zoom,
  onCropComplete,
  setCrop,
  setZoom,
  handleCrop,
  handleUseAsIs,
  setSelectedPhoto,
  selectedAspectRatio,
  setSelectedAspectRatio,
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex flex-col">
    <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <h2 className="text-xl font-semibold">Crop Image</h2>
      <select
        value={selectedAspectRatio.name}
        onChange={(e) =>
          setSelectedAspectRatio(
            ALLOWED_ASPECT_RATIOS.find((ratio) => ratio.name === e.target.value)
          )
        }
        className="bg-gray-700 text-white px-3 py-2 rounded-md"
      >
        {ALLOWED_ASPECT_RATIOS.map((ratio) => (
          <option key={ratio.name} value={ratio.name}>
            {ratio.name}
          </option>
        ))}
      </select>
    </div>
    <div className="flex-grow relative">
      <Cropper
        image={selectedPhoto.url}
        crop={crop}
        zoom={zoom}
        aspect={selectedAspectRatio.value}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
    <div className="bg-gray-800 p-4 flex justify-end space-x-4">
      <button
        onClick={() => setSelectedPhoto(null)}
        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
      >
        Cancel
      </button>
      <button
        onClick={handleUseAsIs}
        className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-300"
      >
        Use As Is
      </button>
      <button
        onClick={handleCrop}
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
      >
        Crop
      </button>
    </div>
  </div>
);

const FileUpload = ({ label, onChange, preview, inputRef }) => (
  <div>
    <label className="block mb-2 font-semibold text-gray-700">{label}</label>
    <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-500 transition-colors duration-300">
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        ref={inputRef}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      {preview ? (
        <img
          src={preview}
          alt={`${label} Preview`}
          className="max-h-48 mx-auto rounded-lg"
        />
      ) : (
        <div className="text-center">
          <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-1 text-sm text-gray-600">
            Click or drag file to upload
          </p>
        </div>
      )}
    </div>
  </div>
);

export default UploadPhotosComponent;


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (frontalPhoto && profilePhoto && age && gender) {
  //     setIsUploading(true);
  //     try {
  //       const response = await uploadPhotos(userId, {
  //         photos: [frontalPhoto, profilePhoto],
  //         age,
  //         gender,
  //       });
  //       setFrontalPhoto(null);
  //       setProfilePhoto(null);
  //       setFrontalPhotoPreview(null);
  //       setProfilePhotoPreview(null);
  //       setAge("");
  //       setGender("");
  //       if (frontalInputRef.current) frontalInputRef.current.value = "";
  //       if (profileInputRef.current) profileInputRef.current.value = "";
  //       onUploadSuccess(response);
  //     } catch (error) {
  //       console.error("Failed to upload photos:", error);
  //     } finally {
  //       setIsUploading(false);
  //     }
  //   } else {
  //     alert(
  //       "Please fill out all fields and select both photos before submitting."
  //     );
  //   }
  // };
