import { clsx} from "clsx"
// import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return clsx(inputs)
}

export function formatDate(input){
  const date = new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function absoluteUrl(path) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`
}