export const initialState = {
  pages: [],
  storyText: "",
  hasChanges: false,
  currentImageIndex: 0,
  currentPage: 0,
  bookId: null,
  authorId: null,
  coverImages: [],
  titleImages: [],
  imageLayout: "",
  title: "",
  userPhotosRequestId: null,
  settings:{},
  selectedImageIndex: {
    left: 0,
    right: 0
  },
  backgroundGenerationStatus: {
    coverStatusId: null,
    titleStatusId: null,
    status: null,
    error: null,
  },
  backgroundGenerationResults: {
    coverResults: [],
    titleResults: [],
  },
};

const storyReducer = (state, action) => {
  let isCover;
  switch (action.type) {
    case 'SET_CURRENT_STORY_ID':
      return {
        ...state,
        id: action.payload
      };
    case "SET_STORY_TEXT":
      return { ...state, storyText: action.payload, hasChanges: true };
    case "SET_TITLE":
      return { ...state, title: action.payload, hasChanges: true };
    case "SET_COVER_IMAGE_PROMPT":
      const updatedCoverImages = [...state.coverImages];
      if (updatedCoverImages.length > 0) {
        updatedCoverImages[0].imagePrompt = action.payload;
      } else {
        updatedCoverImages.push({ imagePrompt: action.payload });
      }
      return { ...state, coverImages: updatedCoverImages, hasChanges: true };
    case "SET_USER_PHOTOS_REQUEST_ID":
        return {
          ...state,
          userPhotosRequestId: action.payload,
        };
    case "SET_STORY_STATE":
      console.log("SETTING STATE")
      console.log(action.payload)
      return {
        ...state,
        pages: action.payload.pages,
        storyText: action.payload.storyText,
        title: action.payload.title,
        bookId: action.payload.bookId,
        authorId: action.payload.authorId,
        coverImages: action.payload.coverImages,
        titleImages: action.payload.titleImages,
        imageLayout: action.payload.settings.image_layout,
        userPhotosRequestId: action.payload.userPhotosRequestId || action.payload.settings.user_photos_request_id || state.userPhotosRequestId,
        settings: action.payload.settings,
        hasChanges: false,
      };
    case "UPDATE_FACE_SIZE":
      return {
        ...state,
        pages: state.pages.map(page =>
          page.pageNumber === (action.payload.pageNumber)
            ? { ...page, faceSize: action.payload.faceSize }
            : page
        )
      };
    case "UPDATE_FACE_POSITION":
      return {
        ...state,
        pages: state.pages.map(page =>
          page.pageNumber === (action.payload.pageNumber)
            ? { ...page, facePositioning: action.payload.facePositioning }
            : page
        )
      };
    case "UPDATE_ANGLE_RATIO":
      return {
        ...state,
        pages: state.pages.map(page =>
          page.pageNumber === (action.payload.pageNumber)
            ? { ...page, angleRatio: action.payload.angleRatio }
            : page
        )
      };
    case "TOGGLE_NEW_SEED":
      return {
        ...state,
        pages: state.pages.map(page =>
          page.pageNumber === action.payload.pageNumber
            ? { ...page, newSeed: !page.newSeed }
            : page
        )
      };
    case "UPDATE_USER_PROMPT":
      return {
        ...state,
        pages: state.pages.map(page =>
          page.pageNumber === (action.payload.pageNumber)
            ? { ...page, userPrompt: action.payload.userPrompt }
            : page
        )
      };
    case "RESET_STORY":
      return initialState;
    case "ADD_PAGE":
      const pageCount = state.pages.length + 1;
      return {
        ...state,
        pages: [...state.pages, { pageNumber: pageCount, text_blocks: [{'content':""}], images: [{'imagePrompt': "", 'imageUrl': ""}] }],
      };
    case "SET_IMAGE_PROMPTS":
      return {
        ...state,
        pages: state.pages.map(page => {
          const promptsForPage = action.payload.pages.find(p => p.pageNumber === page.pageNumber);
          if (promptsForPage) {
            return {
              ...page,
              images: page.images.concat(promptsForPage.images)
            };
          }
          return page;
        }),
        coverImages: action.payload.coverImages,
        hasChanges: true,
      };
    case "ADD_OR_REPLACE_IMAGE":
      return addOrReplaceImage(state, action.payload);
    case "TOGGLE_IMAGE_SELECTION":
      if (action.payload.isCover) {
        return {
          ...state,
          coverImages: toggleCoverImageSelection(
            state.coverImages,
            action.payload
          ),
          hasChanges: true,
        };
      } else {
        return {
          ...state,
          pages: toggleImageSelection(state.pages, action.payload),
          hasChanges: true,
        };
      }
    case "UPDATE_IMAGE_PROMPT":
      isCover = action.payload.pageNumber === 0;
      console.log("UPDATE IMAGE PROMPT: ", action.payload, isCover)
      if (isCover) {
        return {
          ...state,
          coverImages: updateCoverImagePrompt(
            state.coverImages,
            action.payload
          ),
          hasChanges: true,
        };
      } else {
        return {
          ...state,
          pages: updatePagePrompt(state.pages, action.payload),
          hasChanges: true,
        };
      }
    case 'ADD_NEW_TEXT_BLOCK':
      console.log('Action:', action)
      const updatedPagesForAdd = state.pages.map((page) => {
        if (page.pageId === action.payload.pageId) {
          return {
            ...page,
            text_blocks: [...page.text_blocks, action.payload.textBlock],
          };
        } else {
          return page;
        }
      });
      return {
        ...state,
        pages: updatedPagesForAdd,
        hasChanges: true,
      };
    case 'UPDATE_PAGE_TEXT_BLOCKS':
      const updatedPages = state.pages.map((page) => {
        if (page.pageId === action.payload.pageId) {
          const updatedTextBlocks = page.text_blocks.map((block) => 
            block.id === action.payload.updatedTextBlock.id
              ? action.payload.updatedTextBlock
              : block
          );
          return {
            ...page,
            text_blocks: updatedTextBlocks,
          };
        } else {
          return page;
        }
      });
      return {
        ...state,
        pages: updatedPages,
        hasChanges: true,
      };
    case 'DELETE_TEXT_BLOCK':
      console.log('Action:', action);
      const newState = {
        ...state,
        pages: state.pages.map((page) => {
          console.log('Processing page:', page.id);
          if (page.pageId === action.payload.pageId) {
            console.log('Matching page found:', page.pageId);
            const newTextBlocks = page.text_blocks.filter(
              (block) => block.id !== action.payload.textBlockId
            );
            console.log('Filtered text_blocks:', newTextBlocks);
            return {
              ...page,
              text_blocks: newTextBlocks,
            };
          } else {
            return page;
          }
        }),
        hasChanges: true,
      };
      console.log('New state:', newState);
      return newState;
    case "SET_COVER":
      const { cover, isFrontCover } = action.payload;
      console.log("ARE WE SETTING THE COVER: ", cover, isFrontCover)
      return {
        ...state,
        coverImages: state.coverImages.map(c => ({
          ...c,
          is_default: c.imageId === cover.imageId || (c.is_default && c.is_front_cover !== isFrontCover),
          is_front_cover: isFrontCover ? c.imageId === cover.imageId : c.is_front_cover,
          // is_front_cover: isFrontCover ? c.imageId === cover.imageId : c.is_front_cover,
          // is_default: c.imageId === cover.imageId,
        })),
        hasChanges: true,
      };
    case "SET_DEFAULT_IMAGE":
      const { pageNumber, imageId, rightImageId } = action.payload;
      isCover = pageNumber === 0;
      console.log("Setting default image: ", pageNumber, imageId, isCover)
      
      return {
        ...state,
        pages: state.pages.map((page, idx) => {
          // Handle left page
          if (idx === pageNumber - 1) {
            return {
              ...page,
              images: page.images.map((image) => ({
                ...image,
                is_default: image.imageId === imageId,
              })),
            };
          }
          // Handle right page if double layout
          if (action.payload.layout === "double" && idx === pageNumber) {
            return {
              ...page,
              images: page.images.map((image) => ({
                ...image,
                is_default: image.imageId === rightImageId,
              })),
            };
          }
          return page;
        }),
        hasChanges: true,
      };      
      // return {
      //   ...state,
      //   pages: state.pages.map((page, idx) => {
      //     if (idx !== pageNumber - 1) return page;
      //     return {
      //       ...page,
      //       images: page.images.map((image, idx) => ({
      //         ...image,
      //         is_default: image.imageId === imageId,
      //       })),
      //     };
      //   }),
      //   hasChanges: true,
      // };
    case "CLEAR_ALL_SELECTED_IMAGES":
      return {
        ...state,
        coverImages: state.coverImages.map(image => ({ ...image, isSelected: false })),
        pages: state.pages.map(page => ({
          ...page,
          images: page.images.map(image => ({ ...image, isSelected: false }))
        }))
      };
    case "UPDATE_PAGE_NUMBER":
      return {
        ...state,
        currentPage: action.payload.pageNumber
      };
    case "UPDATE_SELECTED_IMAGE_INDEX":
      return {
        ...state,
        selectedImageIndex: {
          ...state.selectedImageIndex,
          [action.payload.side]: action.payload.selectedImageIndex
        }
      };
    case "UPDATE_IMAGE_LAYOUT":
      return {
        ...state,
        imageLayout: action.payload.imageLayout
      };
    case "SET_BACKGROUND_GENERATION_STATUS":
      console.log("UPDATE BACKGROUND GENERATION STATUS: ", action.payload)
      return {
        ...state,
        backgroundGenerationStatus: {
          coverStatusId: action.payload.coverStatusId,
          titleStatusId: action.payload.titleStatusId,
          status: action.payload.status,
          error: action.payload.error,
        },
      };

    case "SET_BACKGROUND_GENERATION_RESULTS":
      return {
        ...state,
        backgroundGenerationResults: {
          coverResults: action.payload.coverResults,
          titleResults: action.payload.titleResults,
        },
        backgroundGenerationStatus: {
          ...state.backgroundGenerationStatus,
          status: action.payload.status,
        },
      };
    case "REORDER_PAGES":
      console.log("REORDER THEM PAGES: ", action.payload)
      return {
        ...state,
        pages: action.payload,
        hasChanges: true,
      };
    case "DELETE_PAGE":
      const pagesSansDeleted = state.pages
        .filter(page => page.pageNumber !== action.payload)
        .map((page, index) => ({
          ...page,
          pageNumber: index + 1
        }));
      return {
        ...state,
        pages: pagesSansDeleted,
        hasChanges: true,
      };
    default:
      return state;
  }
};

const addOrReplaceImage = (state, { pageNumber, image }) => {
  console.log("ADD OR REPLACE IMAGE: ", pageNumber, image)
  if (pageNumber === 0) {
    console.log("Updating a cover image")
    const existingCoverImageIndex = state.coverImages.findIndex(
      (img) => img.imageId === image.imageId
    );

    let newCoverImages;
    if (existingCoverImageIndex !== -1) {
      newCoverImages = [
        ...state.coverImages.slice(0, existingCoverImageIndex),
        image,
        ...state.coverImages.slice(existingCoverImageIndex + 1),
      ];
    } else {
      newCoverImages = [image, ...state.coverImages];
    }

    return { ...state, coverImages: newCoverImages, hasChanges: true };
  } else {
    console.log("Updating pages")
    const newPages = state.pages.map((page, i) => {
      if (i !== pageNumber - 1) return page;

      const pageImages = page.images || [];
      const existingImageIndex = pageImages.findIndex(
        (img) => img.imageId === image.imageId
      );

      let newPageImages;
      if (existingImageIndex !== -1) {
        newPageImages = [
          ...pageImages.slice(0, existingImageIndex),
          image,
          ...pageImages.slice(existingImageIndex + 1),
        ];
      } else {
        newPageImages = [image, ...pageImages];
      }

      return { ...page, images: newPageImages };
    });

    return { ...state, pages: newPages, hasChanges: true };
  }
};

const toggleImageSelection = (pages, { pageNumber, imageId }) => {
  return pages.map((page) => {
    if (page.pageNumber !== pageNumber) return page;
    const updatedImages = page.images.map((image) => {
      if (image.imageId === imageId) {
        return { ...image, isSelected: !image.isSelected };
      }
      return image;
    });
    return { ...page, images: updatedImages };
  });
};

const toggleCoverImageSelection = (coverImages, { imageId }) => {
  return coverImages.map((image) => {
    if (image.imageId === imageId) {
      return { ...image, isSelected: !image.isSelected };
    }
    return image;
  });
};

const updatePagePrompt = (pages, { pageNumber, newPrompt }) => {
  return pages.map((page, i) => {
    if (i !== pageNumber - 1) return page;
    return { ...page, userPrompt: newPrompt };
  });
};

const updateCoverImagePrompt = (coverImages, { newPrompt }) => {
  // For cover, we might want to update a specific field or the entire cover object
  // This depends on how your cover data is structured
  return { ...coverImages, userPrompt: newPrompt };
};

// const updateImagePrompt = (pages, { pageNumber, imageIndex, newPrompt, oldPrompt }) => {
//   return pages.map((page, i) => {
//     if (i !== pageNumber-1) return page;
//     const updatedImages = page.images.map((image, idx) => {
//       if (idx === imageIndex) {
//         console.log("UPDATE PAGE IMAGE PROMPT: ", image, idx, imageIndex, newPrompt)
//         return { ...image, imagePrompt: newPrompt, storedPrompt: oldPrompt};
//       }
//       return image;
//     });
//     return { ...page, images: updatedImages };
//   });
// };

// const updateCoverImagePrompt = (coverImages, { imageIndex, newPrompt, oldPrompt }) => {
//   console.log("UPDATE COVER IMAGE PROMPT: ", coverImages, imageIndex, newPrompt, oldPrompt)
//   return coverImages.map((image, idx) => {
//     if (idx === imageIndex) {
//       return { ...image, imagePrompt: newPrompt, storedPrompt: oldPrompt };
//     }
//     return image;
//   });
// };

export default storyReducer;