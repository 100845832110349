import React from 'react';
import { BookOpenIcon, RectangleGroupIcon, UserIcon, Square3Stack3DIcon, PhotoIcon } from '@heroicons/react/24/outline';

const AboutPage = () => {
  return (
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">About Us</h1>
        <p className="text-xl text-gray-600 mb-12 text-center">
          Welcome to Quill where creativity meets technology! Our platform empowers users to generate personalized children's books with ease.
        </p>
        
        <div className="space-y-12">
          <Section 
            title="Quick Start" 
            icon={BookOpenIcon}
            content="Jump right in and create a story by simply typing in a prompt. Our intuitive interface will guide you through the rest."
          />

          <Section 
            title="Template Section" 
            icon={RectangleGroupIcon}
            content={
              <>
                <p className="mb-4">For a more guided experience, use our template section. Here, you can input specific details such as:</p>
                <ul className="list-disc list-inside mb-4 grid grid-cols-2 gap-2">
                  <li>Character Name</li>
                  <li>Age</li>
                  <li>Gender</li>
                  <li>Theme/Message</li>
                  <li>Type of Conflict</li>
                  <li>Setting</li>
                  <li>Narrative Repetition</li>
                  <li>Story Repetition</li>
                  <li>Rhythm and Rhyme</li>
                </ul>
                <p>After customizing your story, you can select the image layout: either one image per page or one image per two pages. You will also need to upload a photo of your child on the profile page.</p>
              </>
            }
          />

          <Section 
            title="Prewritten Stories" 
            icon={Square3Stack3DIcon}
            content="Start from a prewritten story, review the story and image prompts, and proceed when you're happy with the content."
          />

          <Section 
            title="Premade Books" 
            icon={BookOpenIcon}
            content="Begin with a premade book and personalize it by replacing characters in the images with your child. You won't need to select the image layout, as it's already set. You only need to upload the photo of your child."
          />

          <Section 
            title="Profile Page" 
            icon={UserIcon}
            content="On your profile page, you can find your saved books. You have the options to preview them, purchase them, download them as PDFs, or continue editing. When you choose to edit, it takes you to the writer page, where you can verify the story and image prompts before moving to the book page to generate images."
          />
        </div>

        <p className="text-xl text-gray-600 mt-12 text-center">
          We hope you enjoy creating magical stories with our app!
        </p>
      </div>
    </div>
  );
};

const Section = ({ title, icon: Icon, content }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6 flex items-center">
      <Icon className="h-6 w-6 text-gray-400 mr-3" />
      <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:p-6 text-gray-600">
      {content}
    </div>
  </div>
);

export default AboutPage;

// import React from 'react';

// const AboutPage = () => {
//   return (
//     <div className="bg-gray-100 p-8 grow">
//       <h1 className="text-4xl font-bold mb-4 text-center">About Us</h1>
//       <p className="text-lg mb-4">
//         Welcome to our web app where creativity meets technology! Our platform empowers users to generate personalized children's books with ease. Whether you want to craft a story from scratch or start with one of our templates, we've got you covered.
//       </p>
      
//       <section className="mb-8">
//         <h2 className="text-3xl font-semibold mb-2">Quick Start</h2>
//         <p className="text-md mb-2">
//           Jump right in and create a story by simply typing in a prompt. Our intuitive interface will guide you through the rest.
//         </p>
//       </section>

//       <section className="mb-8">
//         <h2 className="text-3xl font-semibold mb-2">Template Section</h2>
//         <p className="text-md mb-2">
//           For a more guided experience, use our template section. Here, you can input specific details such as:
//         </p>
//         <ul className="list-disc list-inside mb-2">
//           <li>Character Name</li>
//           <li>Age</li>
//           <li>Gender</li>
//           <li>Theme/Message</li>
//           <li>Type of Conflict</li>
//           <li>Setting</li>
//           <li>Narrative Repetition</li>
//           <li>Story Repetition</li>
//           <li>Rhythm and Rhyme</li>
//         </ul>
//         <p className="text-md mb-2">
//           After customizing your story, you can select the image layout: either one image per page or one image per two pages. You will also need to upload a photo of your child on the profile page.
//         </p>
//       </section>

//       <section className="mb-8">
//         <h2 className="text-3xl font-semibold mb-2">Prewritten Stories</h2>
//         <p className="text-md mb-2">
//           Start from a prewritten story, review the story and image prompts, and proceed when you're happy with the content.
//         </p>
//       </section>

//       <section className="mb-8">
//         <h2 className="text-3xl font-semibold mb-2">Premade Books</h2>
//         <p className="text-md mb-2">
//           Begin with a premade book and personalize it by replacing characters in the images with your child. You won't need to select the image layout, as it's already set. You only need to upload the photo of your child.
//         </p>
//       </section>

//       <section className="mb-8">
//         <h2 className="text-3xl font-semibold mb-2">Profile Page</h2>
//         <p className="text-md mb-2">
//           On your profile page, you can find your saved books. You have the options to preview them, purchase them, download them as PDFs, or continue editing. When you choose to edit, it takes you to the writer page, where you can verify the story and image prompts before moving to the book page to generate images.
//         </p>
//       </section>

//       <p className="text-lg">
//         We hope you enjoy creating magical stories with our app!
//       </p>
//     </div>
//   );
// };

// export default AboutPage;
