import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import Toolbar from "./Toolbar";

const TextBlockEditor = ({
  textBlock,
  onAddTextBlock,
  onUpdateTextBlock,
  onDeleteTextBlock,
  onSaveTextBlock,
  imageBounds,
  layout,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState("above");
  const nodeRef = useRef(null);
  const boundsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const textareaRef = useRef(null);
  const fontSizeOptions = {
    small: 0.015,
    medium: 0.02,
    large: 0.025,
    "extra-large": 0.03,
  };

  // console.log(textBlock)
  const calculateBounds = useCallback((imageBounds, textBlockWidth, textBlockHeight) => {
    const imageMidPoint = imageBounds.width / 2;
    const imageLeft = imageBounds.left;
    const actualMidPoint = imageMidPoint + imageLeft;
    const adjustForTextArea = textBlockWidth / 2;
    const actualMidPointAdjusted = actualMidPoint - adjustForTextArea;
    const bottom = imageBounds.height - textBlockHeight;
    const left = imageBounds.left - actualMidPointAdjusted;
    const right = imageBounds.width - textBlockWidth - Math.abs(left);
    return {
      top: 0,
      right: right,
      bottom: bottom,
      left: left,
    };
  }, []);

  const calculateFontSize = (sizeCategory, containerWidth) => {
    const baseSize = fontSizeOptions[sizeCategory];
    const minFontSize = 12;
    const maxFontSize = 48;
    const calculatedSize = baseSize * containerWidth;
    return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
  };

  const calculateRequiredHeight = useCallback((width, content, fontSize) => {
    if (!textareaRef.current) return textBlock.height;

    const textarea = textareaRef.current;
    const originalStyles = textarea.style.cssText;
    
    textarea.style.width = `${width}px`;
    textarea.style.height = 'auto';
    textarea.value = content;
    textarea.style.fontSize = fontSize;

    const scrollHeight = textarea.scrollHeight;

    textarea.style.cssText = originalStyles;

    return scrollHeight / imageBounds.height;
  }, [imageBounds, textBlock.height]);

  useEffect(() => {
    if (imageBounds && textBlock.width && textBlock.height) {
      const calculatedBounds = calculateBounds(imageBounds, textBlock.width * imageBounds.width, textBlock.height * imageBounds.height);
      boundsRef.current = calculatedBounds;
      setIsLoading(false);
    }
  }, [imageBounds, textBlock.width, textBlock.height, calculateBounds]);

  useEffect(() => {
    if (!isLoading && imageBounds && textBlock.width) {
      const fontSize = calculateFontSize(textBlock.fontSize, imageBounds.width);
      const newWidth = textBlock.width * imageBounds.width;
      let newHeight = calculateRequiredHeight(newWidth, textBlock.content, `${fontSize}px`);
      const maxHeight = imageBounds.height - textBlock.y * imageBounds.height;
      newHeight = Math.min(newHeight, maxHeight / imageBounds.height);
      if (newHeight !== textBlock.height) {
        const updatedTextBlock = {
          ...textBlock,
          height: newHeight,
        };
        onUpdateTextBlock(updatedTextBlock);
      }
    }
  }, [isLoading, imageBounds, textBlock, calculateRequiredHeight, onUpdateTextBlock, calculateFontSize]);

  const handleResize = useCallback((newWidth) => {
    if (imageBounds) {
      newWidth = Math.min(newWidth, imageBounds.width);
      const relativeWidth = newWidth / imageBounds.width;
      const fontSize = calculateFontSize(textBlock.fontSize, imageBounds.width);
      let newHeight = calculateRequiredHeight(newWidth, textBlock.content, `${fontSize}px`);
      const maxHeight = imageBounds.height - textBlock.y * imageBounds.height;
      newHeight = Math.min(newHeight, maxHeight / imageBounds.height);
      const updatedTextBlock = {
        ...textBlock,
        width: relativeWidth,
        height: newHeight,
      };
      onUpdateTextBlock(updatedTextBlock);
    }
  }, [imageBounds, textBlock, calculateRequiredHeight, onUpdateTextBlock, calculateFontSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (nodeRef.current && imageBounds) {
        const rect = nodeRef.current.getBoundingClientRect();
        handleResize(rect.width);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleResize, imageBounds]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (!isLoading && nodeRef.current) {
      updateToolbarPosition();
    }
  }, [isLoading, textBlock.x, textBlock.y, textBlock.width, textBlock.height]);

  const updateToolbarPosition = () => {
    const textBlockRect = nodeRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    setToolbarPosition(
      textBlockRect.top < 100
        ? "below"
        : textBlockRect.bottom + 100 > viewportHeight
        ? "above"
        : toolbarPosition
    );
  };

  const handleStyleChange = (styleName, value) => {
    const updatedTextBlock = {
      ...textBlock,
      [styleName]: getStyleValue(styleName, value),
    };
    onUpdateTextBlock(updatedTextBlock);
  };

  const getStyleValue = (styleName, value) => {
    switch (styleName) {
      case "fontWeight":
        return textBlock.fontWeight === "bold" ? "normal" : "bold";
      case "fontStyle":
        return textBlock.fontStyle === "italic" ? "normal" : "italic";
      case "textDecoration":
        return textBlock.textDecoration === "underline" ? "none" : "underline";
      case "backdropFilter":
        return value === "none" ? "none" : `blur(${value}px)`;
      case "backgroundColor":
        return value;
      case "fontSize":
        return value;
      default:
        return value;
    }
  };

  const updatePosition = (updatedTextBlock) => {
    if (imageBounds && boundsRef.current) {
      const bounds = boundsRef.current;
      const totalWidth = bounds.right - bounds.left;
      const totalHeight = bounds.bottom - bounds.top;
      const x = bounds.left + (updatedTextBlock.relativeX * totalWidth);
      const y = bounds.top + (updatedTextBlock.relativeY * totalHeight);
      
      return { x, y };
    }
    return { x: updatedTextBlock.x, y: updatedTextBlock.y };
  };

  const handleStop = (e, data) => {
    if (imageBounds && boundsRef.current) {
      const bounds = boundsRef.current;
      const { height, width } = imageBounds;
      const totalWidth = bounds.right - bounds.left;
      const totalHeight = bounds.bottom - bounds.top;
  
      const topXVal = data.x - bounds.left;
      const topYVal = data.y - bounds.top;
  
      const relativeX = totalWidth !== 0 ? topXVal / totalWidth : 0;
      const relativeY = totalHeight !== 0 ? topYVal / totalHeight : 0;
  
      const otherRelativeX = width !== 0 ? (data.x - bounds.left) / width : 0;
      const otherRelativeY = height !== 0 ? (data.y - bounds.top) / height : 0;
  
      const updatedTextBlock = {
        ...textBlock,
        x: otherRelativeX,
        y: otherRelativeY,
        relativeX,
        relativeY,
      };
  
      onUpdateTextBlock(updatedTextBlock);
      updateToolbarPosition();
    }
  };

  const handleResizeStart = (e) => {
    const startWidth = nodeRef.current.offsetWidth;
    const startX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
    const doDrag = (dragEvent) => {
      dragEvent.preventDefault();
      const currentX = dragEvent.type.includes('mouse') ? dragEvent.clientX : dragEvent.touches[0].clientX;
      let newWidth = startWidth + currentX - startX;
      newWidth = Math.max(newWidth, 50); // Minimum width
      handleResize(newWidth);
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag, { passive: false });
      document.removeEventListener("mouseup", stopDrag);
      document.removeEventListener("touchmove", doDrag, { passive: false });
      document.removeEventListener("touchend", stopDrag);
    };

    document.addEventListener("mousemove", doDrag, { passive: false });
    document.addEventListener("mouseup", stopDrag);
    document.addEventListener("touchmove", doDrag, { passive: false });
    document.addEventListener("touchend", stopDrag);
  };
  
  const width = imageBounds ? `${textBlock.width * imageBounds.width}px` : `${textBlock.width}px`;
  const height = imageBounds ? `${textBlock.height * imageBounds.height}px` : `${textBlock.height}px`;
  const position = updatePosition(textBlock);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (  
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onStop={handleStop}
      handle=".drag-handle"
      bounds={boundsRef.current}
    >
      <div
        ref={nodeRef}
        className="absolute bg-white rounded"
        style={{ width: width, height: height, backgroundColor: 'transparent', border: 'none', zIndex:10}}
        onFocus={() => setIsFocused(true)}
        tabIndex={0}
      >
        <textarea
          ref={textareaRef}
          value={textBlock.content}
          onChange={(e) => {
            const updatedTextBlock = { ...textBlock, content: e.target.value };
            const fontSize = calculateFontSize(textBlock.fontSize, imageBounds.width);
            const newHeight = calculateRequiredHeight(parseFloat(width), e.target.value, `${fontSize}px`);
            updatedTextBlock.height = newHeight;
            onUpdateTextBlock(updatedTextBlock);
          }}
          className="w-full h-full"
          style={{
            resize: "none",
            fontFamily: textBlock.fontFamily,
            fontSize: imageBounds
              ? `${calculateFontSize(textBlock.fontSize, imageBounds.width)}px`
              : `${fontSizeOptions[textBlock.fontSize] * 100}%`,
            color: textBlock.color,
            textShadow: textBlock.textShadow,
            fontWeight: textBlock.fontWeight,
            fontStyle: textBlock.fontStyle,
            textDecoration: textBlock.textDecoration,
            backgroundColor: textBlock.backgroundColor + Math.round(textBlock.opacity * 255).toString(16).padStart(2, "0"),
            backdropFilter: textBlock.backdropFilter,
          }}
        />
        {isFocused && (
          <Toolbar
            textBlock={textBlock}
            handleStyleChange={handleStyleChange}
            onSaveTextBlock={onSaveTextBlock}
            onDeleteTextBlock={onDeleteTextBlock}
            onAddTextBlock={onAddTextBlock}
            handleResizeStart={handleResizeStart}
            toolbarPosition={toolbarPosition}
          />
        )}
      </div>
    </Draggable>
  );
};

export default TextBlockEditor;

// // // OLD CODE // // //
// const TextBlockEditor = ({
//   textBlock,
//   onAddTextBlock,
//   onUpdateTextBlock,
//   onDeleteTextBlock,
//   onSaveTextBlock,
//   imageBounds,
//   layout,
// }) => {
//   const [isFocused, setIsFocused] = useState(false);
//   const [toolbarPosition, setToolbarPosition] = useState("above");
//   const nodeRef = useRef(null);
//   const boundsRef = useRef(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const fontSizeOptions = {
//     small: 0.015,
//     medium: 0.02,
//     large: 0.025,
//     "extra-large": 0.03,
//   };

//   const calculateBounds = useCallback((imageBounds, textBlockWidth, textBlockHeight) => {
//     const imageMidPoint = imageBounds.width / 2;
//     const imageLeft = imageBounds.left;
//     const actualMidPoint = imageMidPoint + imageLeft;
//     const adjustForTextArea = textBlockWidth / 2;
//     const actualMidPointAdjusted = actualMidPoint - adjustForTextArea;
//     const bottom = imageBounds.height - textBlockHeight;
//     const left = imageBounds.left - actualMidPointAdjusted;
//     const right = imageBounds.width - textBlockWidth - Math.abs(left);
//     return {
//       top: 0,
//       right: right,
//       bottom: bottom,
//       left: left,
//     };
//   }, []);

//   useEffect(() => {
//     if (imageBounds && textBlock.width && textBlock.height) {
//       const calculatedBounds = calculateBounds(imageBounds, textBlock.width * imageBounds.width, textBlock.height * imageBounds.height);
//       boundsRef.current = calculatedBounds;
//       setIsLoading(false);
//     }
//   }, [imageBounds, textBlock.width, textBlock.height, calculateBounds]);

//   useEffect(() => {
//     const handleResize = () => {
//       if (nodeRef.current && imageBounds) {
//         const rect = nodeRef.current.getBoundingClientRect();
//         const updatedTextBlock = {
//           ...textBlock,
//           width: rect.width / imageBounds.width,
//           height: rect.height / imageBounds.height,
//         };
//         onUpdateTextBlock(updatedTextBlock);
//         updatePosition(updatedTextBlock);
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [textBlock, imageBounds, onUpdateTextBlock]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (nodeRef.current && !nodeRef.current.contains(event.target)) {
//         setIsFocused(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   useEffect(() => {
//     if (!isLoading && nodeRef.current) {
//       updateToolbarPosition();
//     }
//   }, [isLoading, textBlock.x, textBlock.y, textBlock.width, textBlock.height]);

//   const updateToolbarPosition = () => {
//     const textBlockRect = nodeRef.current.getBoundingClientRect();
//     const viewportHeight = window.innerHeight;
//     setToolbarPosition(
//       textBlockRect.top < 100
//         ? "below"
//         : textBlockRect.bottom + 100 > viewportHeight
//         ? "above"
//         : toolbarPosition
//     );
//   };

//   const handleStyleChange = (styleName, value) => {
//     const updatedTextBlock = {
//       ...textBlock,
//       [styleName]: getStyleValue(styleName, value),
//     };
//     console.log("STYLE CHANGING", styleName, value)
//     console.log("UPDATED TEXT BLOCK: ", updatedTextBlock)
//     onUpdateTextBlock(updatedTextBlock);
//   };

//   const getStyleValue = (styleName, value) => {
//     switch (styleName) {
//       case "fontWeight":
//         return textBlock.fontWeight === "bold" ? "normal" : "bold";
//       case "fontStyle":
//         return textBlock.fontStyle === "italic" ? "normal" : "italic";
//       case "textDecoration":
//         return textBlock.textDecoration === "underline" ? "none" : "underline";
//       case "backdropFilter":
//         return value === "none" ? "none" : `blur(${value}px)`;
//       case "backgroundColor":
//         return value;
//       case "fontSize":
//           return value;
//       default:
//         return value;
//     }
//   };

//   const updatePosition = (updatedTextBlock) => {
//     if (imageBounds && boundsRef.current) {
//       const bounds = boundsRef.current;
//       const totalWidth = bounds.right - bounds.left;
//       const totalHeight = bounds.bottom - bounds.top;
//       console.log(bounds.left, updatedTextBlock.relativeX, totalWidth)
//       const x = bounds.left + (updatedTextBlock.relativeX * totalWidth);
//       const y = bounds.top + (updatedTextBlock.relativeY * totalHeight);
      
//       return { x, y };
//     }
//     return { x: updatedTextBlock.x, y: updatedTextBlock.y };
//   };

//   const handleStop = (e, data) => {
//     if (imageBounds && boundsRef.current) {
//       const bounds = boundsRef.current;
//       const {height, width} = imageBounds;
//       const totalWidth = bounds.right - bounds.left;
//       const totalHeight = bounds.bottom - bounds.top;
//       const relativeX = (data.x - bounds.left) / totalWidth;
//       const relativeY = (data.y - bounds.top) / totalHeight;
//       const otherRelativeX = (data.x - bounds.left) / width;
//       const otherRelativeY = (data.y - bounds.top) / height;
//       const updatedTextBlock = {
//         ...textBlock,
//         x: otherRelativeX,
//         y: otherRelativeY,
//         relativeX,
//         relativeY,
//       };
//       onUpdateTextBlock(updatedTextBlock);
//       updateToolbarPosition();
//     }
//   };

//   const handleResizeStart = (e) => {
//     const startWidth = nodeRef.current.offsetWidth;
//     const startX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;

//     const doDrag = (dragEvent) => {
//       dragEvent.preventDefault();
//       const currentX = dragEvent.type.includes('mouse') ? dragEvent.clientX : dragEvent.touches[0].clientX;
//       let newWidth = startWidth + currentX - startX;
//       newWidth = Math.max(newWidth, 50);
//       handleResize(newWidth);
//     };

//     const stopDrag = () => {
//       document.removeEventListener("mousemove", doDrag, { passive: false });
//       document.removeEventListener("mouseup", stopDrag);
//       document.removeEventListener("touchmove", doDrag, { passive: false });
//       document.removeEventListener("touchend", stopDrag);
//     };

//     document.addEventListener("mousemove", doDrag, { passive: false });
//     document.addEventListener("mouseup", stopDrag);
//     document.addEventListener("touchmove", doDrag, { passive: false });
//     document.addEventListener("touchend", stopDrag);
//   };

//   const handleResize = (newWidth) => {
//     if (imageBounds) {
//       newWidth = Math.min(newWidth, imageBounds.width);
//       const relativeWidth = newWidth / imageBounds.width;
//       const updatedTextBlock = {
//         ...textBlock,
//         width: relativeWidth,
//       };
//       onUpdateTextBlock(updatedTextBlock);
//     }
//   };

//   const calculateFontSize = (sizeCategory, containerWidth) => {
//     const baseSize = fontSizeOptions[sizeCategory];
//     const minFontSize = 12;
//     const maxFontSize = 48;
//     const calculatedSize = baseSize * containerWidth;
//     return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
//   };
  
//   const width = imageBounds ? `${textBlock.width * imageBounds.width}px` : `${textBlock.width}px`;
//   const height = imageBounds ? `${textBlock.height * imageBounds.height}px` : `${textBlock.height}px`;
//   console.log(textBlock)
//   const position = updatePosition(textBlock);


//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (  
//     <Draggable
//       nodeRef={nodeRef}
//       position={position}
//       onStop={handleStop}
//       handle=".drag-handle"
//       bounds={boundsRef.current}
//     >
//       <div
//         ref={nodeRef}
//         className="absolute bg-white rounded"
//         style={{ width: width, height: height, backgroundColor: 'transparent', border: 'none', zIndex:10}}
//         onFocus={() => setIsFocused(true)}
//         tabIndex={0}
//       >
//         <textarea
//           value={textBlock.content}
//           onChange={(e) =>
//             onUpdateTextBlock({ ...textBlock, content: e.target.value })
//           }
//           className="w-full h-full"
//           style={{
//             fontFamily: textBlock.fontFamily,
//             fontSize: imageBounds
//             ? `${calculateFontSize(textBlock.fontSize, imageBounds.width)}px`
//             : `${fontSizeOptions[textBlock.fontSize] * 100}%`,
//             color: textBlock.color,
//             textShadow: textBlock.textShadow,
//             fontWeight: textBlock.fontWeight,
//             fontStyle: textBlock.fontStyle,
//             textDecoration: textBlock.textDecoration,
//             backgroundColor: textBlock.backgroundColor + Math.round(textBlock.opacity * 255).toString(16).padStart(2, "0"),
//             backdropFilter: textBlock.backdropFilter,
//           }}
//         />
//         {isFocused && (
//             <Toolbar
//             textBlock={textBlock}
//             handleStyleChange={handleStyleChange}
//             onSaveTextBlock={onSaveTextBlock}
//             onDeleteTextBlock={onDeleteTextBlock}
//             onAddTextBlock={onAddTextBlock}
//             handleResizeStart={handleResizeStart}
//             toolbarPosition={toolbarPosition}
//           />
//         )}
//       </div>
//     </Draggable>
//   );
// };

// export default TextBlockEditor;
