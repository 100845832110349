import React, { useState, useRef, useEffect } from "react";
import emptyImage from "../assets/emptyimage.png";
import "./ImageArea.css";

const ImageArea = ({
  pageNumber,
  isGeneratingImages,
  passedImages,
  onImageBoundsChange,
  selectedImageIndex
}) => {
  const imgRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (onImageBoundsChange && imageLoaded) {
      const imageBounds = calculateImageBounds();
      onImageBoundsChange(imageBounds);
    }
  }, [selectedImageIndex, onImageBoundsChange, imageLoaded]);

  const calculateImageBounds = () => {
    if (imgRef.current) {
      const imgRect = imgRef.current.getBoundingClientRect();
      return imgRect;
    }
    return null;
  };

  const safeSelectedImageIndex = Math.min(selectedImageIndex, passedImages.length - 1);
  return (
    <div className="w-full h-full flex justify-center items-center">
    {passedImages.length > 0 ? (
      <div className="relative h-full">
        <img
          ref={imgRef}
          src={passedImages[safeSelectedImageIndex]?.imageUrl || emptyImage}
          alt={`Page ${pageNumber}`}
          className="w-full h-full object-contain"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    ) : (
      <div className="flex justify-center items-center w-full h-full">
        {isGeneratingImages ? (
          <div>Generating images...</div>
        ) : (
          <div>No images available</div>
        )}
      </div>
    )}
  </div>
  );
};

export default ImageArea;

// import React, { useState, useRef, useEffect } from "react";
// import { useStory } from "../context/StoryContext";
// import emptyImage from "../assets/emptyimage.png";
// import "./ImageArea.css";

// const ImageArea = ({
//   pageNumber,
//   isGeneratingImages,
//   onImageBoundsChange,
//   selectedImageIndex
// }) => {
//   const { storyState } = useStory();
//   const imgRef = useRef(null);
//   const [imageLoaded, setImageLoaded] = useState(false);

//   const page = storyState.pages?.find((page) => page.pageNumber === pageNumber);
//   const imagesFromState = pageNumber === 0 ? storyState.coverImages : page?.images;
//   const pageImages = imagesFromState || [];
//   const sortedPageImages = [
//     ...pageImages?.filter((image) => image.is_default),
//     ...pageImages?.filter((image) => !image.is_default),
//   ];

//   useEffect(() => {
//     if (onImageBoundsChange && imageLoaded) {
//       const imageBounds = calculateImageBounds();
//       onImageBoundsChange(imageBounds);
//     }
//   }, [selectedImageIndex, onImageBoundsChange, imageLoaded]);

//   const calculateImageBounds = () => {
//     if (imgRef.current) {
//       const imgRect = imgRef.current.getBoundingClientRect();
//       return imgRect;
//     }
//     return null;
//   };

//   const safeSelectedImageIndex = Math.min(selectedImageIndex, sortedPageImages.length - 1);
//   return (
//     <div className="w-full h-full flex justify-center items-center">
//     {sortedPageImages.length > 0 ? (
//       <div className="relative h-full">
//         <img
//           ref={imgRef}
//           src={sortedPageImages[safeSelectedImageIndex]?.imageUrl || emptyImage}
//           alt={`Page ${pageNumber}`}
//           className="w-full h-full object-contain"
//           onLoad={() => setImageLoaded(true)}
//         />
//       </div>
//     ) : (
//       <div className="flex justify-center items-center w-full h-full">
//         {isGeneratingImages ? (
//           <div>Generating images...</div>
//         ) : (
//           <div>No images available</div>
//         )}
//       </div>
//     )}
//   </div>
//   );
// };

// export default ImageArea;