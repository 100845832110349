// textBlockService.js
import {
  getAccessToken,
  refreshAccessToken,
  setAccessToken,
  getRefreshToken,
} from "../utils/auth";

import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`
export const createTextBlock = async (pageId, x, y) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/text-blocks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pageId, x, y }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return createTextBlock(pageId, x, y); // Retry with new token
    } else {
      throw new Error("Failed to create text block");
    }
  }

  return await response.json();
};

export const updateTextBlock = async (textBlock) => {
  const token = getAccessToken();
  console.log("TEXTBLOCK: ", textBlock)
  const response = await fetch(`${base_backend}/story/text-blocks/${textBlock.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(textBlock),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return updateTextBlock(textBlock); // Retry with new token
    } else {
      throw new Error("Failed to update text block");
    }
  }

  return await response.json();
};

export const deleteTextBlock = async (textBlockId) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/text-blocks/${textBlockId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return deleteTextBlock(textBlockId); // Retry with new token
    } else {
      throw new Error("Failed to delete text block");
    }
  }

  return await response.json();
};