import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import UploadPhotosComponent from './UploadPhotosComponent';
import ManagePhotosComponent from './ManagePhotosComponent';

const PhotoSelectionModal = ({ isOpen, onClose, onPhotoSelected }) => {
  const [activeTab, setActiveTab] = useState('select');

  const handleUploadSuccess = (response) => {
    onPhotoSelected(response.requestId);
    onClose();
  };

  const handlePhotoSelect = (requestId) => {
    console.log('Selected photo from photoselectionmodal:', requestId);
    onPhotoSelected(requestId);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-3xl bg-white rounded-lg p-6">
          <Dialog.Title className="text-2xl font-semibold mb-4">Select or Upload Photos</Dialog.Title>
          <div className="mb-4">
            <button
              className={`mr-2 px-4 py-2 rounded-lg ${activeTab === 'select' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('select')}
            >
              Select Existing
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'upload' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('upload')}
            >
              Upload New
            </button>
          </div>
          {activeTab === 'select' ? (
            <ManagePhotosComponent onSelect={handlePhotoSelect} showDeleteButton={false} />
          ) : (
            <UploadPhotosComponent onUploadSuccess={handleUploadSuccess} />
          )}
          <button
            className="mt-4 px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default PhotoSelectionModal;