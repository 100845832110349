import { getAccessToken, refreshAccessToken, setAccessToken, getRefreshToken } from '../utils/auth';
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`
export const generateStory = async (data, bookId=null, retry = true) => {
  const token = getAccessToken();
  data.bookId = bookId;
  const response = await fetch(`${base_backend}/story/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      if (retry) {
        setAccessToken(newToken);
        return generateStory(data, bookId, false);
      } else {
        throw new Error("Authorization failed after retry");
      }
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};

export const copyBook = async (bookId) => {
  const token = getAccessToken();
  try {
    const response = await fetch(`${base_backend}/story/book/copy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ bookId })
    });

    if (!response.ok) {
      if (response.status === 401) {
        const newToken = await refreshAccessToken(getRefreshToken());
        if (!newToken) {
          throw new Error('Unable to refresh token');
        }
        return copyBook(bookId); // Retry with the new token
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const savePages = async (bookId, pages) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/save-pages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ bookId, pages}),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      return savePages(bookId, pages); // Retry with the new token
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};



export const saveBook = async (bookId, title)  => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/save-book`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ bookId, title}),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      return saveBook(bookId, title);
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};

export const getSavedStories = async () => {
  const token = getAccessToken();
  try {
    const response = await fetch(`${base_backend}/story/get-stories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      console.error('HTTP error! status:', response.status, 'response:', response);
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const result = await response.json();
      console.log("Fetched stories:", result);
      return result;
    }
  } catch (error) {
    console.error("Failed to fetch saved stories:", error);
    throw error;
  }
};


export const deleteStoryById = async (storyId) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/book/${storyId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error('Unable to refresh token');
      }
      setAccessToken(newToken);
      return deleteStoryById(storyId); // Retry with new token
    } else if (response.status === 404) {
      throw new Error('Story not found');
    } else {
      throw new Error('Failed to delete story');
    }
  }

  return await response.json();
};

export const getStoryById = async (storyId) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/story/book/${storyId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error('Unable to refresh token');
      }
      setAccessToken(newToken);
      return getStoryById(storyId); // Retry with new token
    } else if (response.status === 404) {
      throw new Error('Story not found');
    } else {
      throw new Error('Failed to fetch story');
    }
  }

  return await response.json();
};

export const generateImagePrompts = async (story, retry = true) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/prompts/prompt-gen`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(story),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      if (retry) {
        setAccessToken(newToken);
        return generateImagePrompts(story, false);
      } else {
        throw new Error("Authorization failed after retry");
      }
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};



export const regenerateImagePrompts = async (story, pagesToRegen, retry = true) => {
  const token = getAccessToken();
  console.log("PAGES TO REGEN: ", pagesToRegen)
  const response = await fetch(`${base_backend}/prompts/regenerate-prompt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({story, pagesToRegen}),
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      if (retry) {
        setAccessToken(newToken);
        return regenerateImagePrompts(story, pagesToRegen, false);
      } else {
        throw new Error("Authorization failed after retry");
      }
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};

export const clearExistingImages = async (bookId, retry = true) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/prompts/clear-images?bookId=${bookId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      if (retry) {
        setAccessToken(newToken);
        return clearExistingImages(bookId, false);
      } else {
        throw new Error("Authorization failed after retry");
      }
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    const result = await response.json();
    return result;
  }
};



// export const saveStory = async (storyState) => {
//   const token = getAccessToken();
//   const response = await fetch(`${base_backend}/story/save-story`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify({ book: storyState }),
//   });

//   if (!response.ok) {
//     if (response.status === 401) {
//       const newToken = await refreshAccessToken(getRefreshToken());
//       if (!newToken) {
//         throw new Error("Unable to refresh token");
//       }
//       return saveStory(storyState);
//     } else {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//   } else {
//     const result = await response.json();
//     return result;
//   }
// };