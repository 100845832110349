import React from 'react';
import TextBlockEditor from '../components/TextBlockEditor';
import useTextBlocks from '../hooks/useTextBlocks';

const TextBlockManager = ({ pageId, isEditable, imageBounds, layout}) => {
  const { text_blocks, handleDeleteTextBlock, handleUpdateTextBlock, handleSaveTextBlock, addNewTextBlock } = useTextBlocks(pageId);

  return (
    <>
      {text_blocks.map((textBlock) =>
          <TextBlockEditor
            key={textBlock.id}
            textBlock={textBlock}
            onUpdateTextBlock={handleUpdateTextBlock}
            onDeleteTextBlock={handleDeleteTextBlock}
            onSaveTextBlock={handleSaveTextBlock}
            onAddTextBlock={addNewTextBlock}
            imageBounds={imageBounds}
            layout={layout}
          />
      )}
    </>
  );
};

export default TextBlockManager;
