import React, { useState, useCallback } from 'react';
import TextBlockManager from '../hooks/textBlockManager';
import ImageArea from './ImageArea';

const Page = ({
  isEditable,
  page,
  isGeneratingImages,
  selectedImageIndex
}) => {
  const [imageBounds, setImageBounds] = useState(null);

  const handleImageBoundsChange = useCallback((bounds) => {
    setImageBounds(bounds);
  }, []);

  const pageImages = page.images || [];
const sortedCurrentImages = [
  ...pageImages?.filter((image) => image.is_default),
  ...pageImages?.filter((image) => !image.is_default),
];
  // console.log("PAGE IMAGES: ", sortedCurrentImages);
  return (
    <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center">
      <div className="flex flex-col items-center relative w-full h-full">
        {imageBounds && (
          <TextBlockManager
            pageId={page.pageId}
            isEditable={isEditable}
            imageBounds={imageBounds}
          />
        )}
        <ImageArea
          isEditable={isEditable}
          passedImages={sortedCurrentImages}
          pageNumber={page.pageNumber}
          isGeneratingImages={isGeneratingImages}
          selectedImageIndex={selectedImageIndex}
          onImageBoundsChange={handleImageBoundsChange}
        />
      </div>
    </div>
  );
};

export default Page;

// const Page = ({
//   isEditable,
//   page,
//   isGeneratingImages,
//   onImageToggle,
//   onPromptEdit,

// }) => {
//   const pageNumber = page.pageNumber;
//   const [imageBounds, setImageBounds] = useState(null);

//   const handleImageBoundsChange = useCallback((bounds) => {
//     setImageBounds(bounds);
//   }, []);

  
//   return (
//     <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center">
//       <div className="flex flex-col items-center relative w-full h-full">
//        {imageBounds && (
//           <TextBlockManager
//             pageId={page.pageId}
//             isEditable={isEditable}
//             imageBounds={imageBounds}
//           />
//         )}
//       <ImageArea
//         isEditable={isEditable}
//         imageAreaPages={page.images}
//         pageNumber={page.pageNumber}
//         isGeneratingImages={isGeneratingImages}
//         onImageToggle={onImageToggle}
//         onPromptEdit={onPromptEdit}
//         onImageBoundsChange={handleImageBoundsChange}
//       />
//       </div>
//     </div>
//   );
// };

// export default Page;

{/* 
      {isEditable && (
        <button
          onClick={handleAddNewTextBlock}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded absolute"
        >
          Add Text Block
        </button>
      )} */}
      // const { addNewTextBlock } = useTextBlocks(pageNumber);

      // const handleAddNewTextBlock = () => {
      //   const x = 0;
      //   const y = 0;
      //   addNewTextBlock(page.pageId, x, y);
      // };
      
      // import useTextBlocks from '../hooks/useTextBlocks';