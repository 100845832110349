import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`
export const forgotPassword = async (email) => {
    try {
      const response = await fetch(
        `${base_backend}/auth/forgotpassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        // Handle response (e.g., showing a message to check their email)
        return data;
      } else {
        throw new Error("Forgot Password request failed");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  
  export const signupUser = async (data) => {
    try {
      const response = await fetch(`${base_backend}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "Login failed");
      }
    } catch (error) {
      console.error("There was an error!", error);
      throw error;
    }
  };

  export const loginUser = async (data) => {
    try {
      const response = await fetch(`${base_backend}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "Login failed");
      }
    } catch (error) {
      console.error("There was an error!", error);
      throw error;
    }
  };