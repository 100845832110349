import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontFamilySelect, FontSizeSelect, ColorPicker, TextShadowSelect, BackgroundStyleControls } from "./StyleComponents";
import { faBold, faItalic, faUnderline, faFont, faPlus, faTextHeight, faAdjust, faImage, faSave, faTrash, faBars, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

const Toolbar = ({ textBlock, handleStyleChange, onSaveTextBlock, onDeleteTextBlock, onAddTextBlock, handleResizeStart, toolbarPosition }) => {
  const [isCompact, setIsCompact] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const toolbarRef = useRef(null);
  
  const fontSizeOptions = {
    small: 0.015,
    medium: 0.02,
    large: 0.025,
    "extra-large": 0.03,
  };

  useEffect(() => {
    const handleResize = () => {
      if (toolbarRef.current) {
        setIsCompact(toolbarRef.current.offsetWidth < 300); // Adjust this threshold as needed
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ToolbarButton = ({ icon, onClick, isActive, className, label }) => (
    <button 
      onClick={onClick} 
      className={`p-1 rounded ${isActive ? 'bg-blue-200' : 'bg-gray-200'} ${className}`}
      title={label}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

  const DropdownMenu = () => (
    <div className="absolute top-full left-0 mt-1 bg-white shadow-md rounded p-2 z-10">
      <FontFamilySelect
        fontFamilies={["Nunito", "Fredoka"]}
        selectedFontFamily={textBlock.fontFamily}
        onFontFamilyChange={(fontFamily) => handleStyleChange("fontFamily", fontFamily)}
      />
      <FontSizeSelect
        fontSizeOptions={Object.keys(fontSizeOptions)}
        selectedFontSize={textBlock.fontSize}
        onFontSizeChange={(fontSize) => handleStyleChange("fontSize", fontSize)}
      />
      <ColorPicker
        selectedColor={textBlock.color}
        onColorChange={(color) => handleStyleChange("color", color)}
      />
      <ToolbarButton 
        icon={faBold} 
        onClick={() => handleStyleChange("fontWeight", textBlock.fontWeight === "bold" ? "normal" : "bold")}
        isActive={textBlock.fontWeight === "bold"}
        label="Bold"
      />
      <ToolbarButton 
        icon={faItalic} 
        onClick={() => handleStyleChange("fontStyle", textBlock.fontStyle === "italic" ? "normal" : "italic")}
        isActive={textBlock.fontStyle === "italic"}
        label="Italic"
      />
      <ToolbarButton 
        icon={faUnderline} 
        onClick={() => handleStyleChange("textDecoration", textBlock.textDecoration === "underline" ? "none" : "underline")}
        isActive={textBlock.textDecoration === "underline"}
        label="Underline"
      />
      <div>
        <FontAwesomeIcon icon={faAdjust} className="text-gray-600 mr-1" />
        <TextShadowSelect
          textShadowOptions={[
            { label: "None", value: "0px 0px 0px #000000" },
            { label: "Light", value: "1px 1px 2px #666" },
            { label: "Heavy", value: "2px 2px 5px #444" },
          ]}
          selectedTextShadow={textBlock.textShadow}
          onTextShadowChange={(textShadow) => handleStyleChange("textShadow", textShadow)}
        />
      </div>
      <div>
        <FontAwesomeIcon icon={faImage} className="text-gray-600 mr-1" />
        <BackgroundStyleControls
          selectedBackdropFilter={textBlock.backdropFilter}
          selectedBackgroundColor={textBlock.backgroundColor}
          selectedOpacity={textBlock.opacity}
          onBackgroundStyleChange={(styleName, value) => handleStyleChange(styleName, value)}
        />
      </div>
    </div>
  );

  return (
    <div 
      ref={toolbarRef}
      className={`${toolbarPosition === "above" ? "bottom-full" : "top-full"} w-full bg-gray-100 p-2 rounded shadow-md`}
      style={{ left: 0, zIndex: 1000 }}
    >
      <div className="flex flex-wrap items-center gap-2">
        <button className="drag-handle cursor-move p-1 bg-gray-200 rounded">⿓</button>
        
        {isCompact ? (
          <div className="relative">
            <ToolbarButton 
              icon={faEllipsisH} 
              onClick={() => setShowDropdown(!showDropdown)} 
              label="More options"
            />
            {showDropdown && <DropdownMenu />}
          </div>
        ) : (
          <>
            <FontFamilySelect
              fontFamilies={["Nunito", "Fredoka"]}
              selectedFontFamily={textBlock.fontFamily}
              onFontFamilyChange={(fontFamily) => handleStyleChange("fontFamily", fontFamily)}
            />
            <FontSizeSelect
              fontSizeOptions={Object.keys(fontSizeOptions)}
              selectedFontSize={textBlock.fontSize}
              onFontSizeChange={(fontSize) => handleStyleChange("fontSize", fontSize)}
            />
            <ColorPicker
              selectedColor={textBlock.color}
              onColorChange={(color) => handleStyleChange("color", color)}
            />
            <ToolbarButton 
              icon={faBold} 
              onClick={() => handleStyleChange("fontWeight", textBlock.fontWeight === "bold" ? "normal" : "bold")}
              isActive={textBlock.fontWeight === "bold"}
              label="Bold"
            />
            <ToolbarButton 
              icon={faItalic} 
              onClick={() => handleStyleChange("fontStyle", textBlock.fontStyle === "italic" ? "normal" : "italic")}
              isActive={textBlock.fontStyle === "italic"}
              label="Italic"
            />
            <ToolbarButton 
              icon={faUnderline} 
              onClick={() => handleStyleChange("textDecoration", textBlock.textDecoration === "underline" ? "none" : "underline")}
              isActive={textBlock.textDecoration === "underline"}
              label="Underline"
            />
            <ToolbarButton 
              icon={faEllipsisH} 
              onClick={() => setShowDropdown(!showDropdown)} 
              label="More options"
            />
            {showDropdown && <DropdownMenu />}
          </>
        )}
        
        <ToolbarButton icon={faSave} onClick={() => onSaveTextBlock(textBlock)} className="bg-blue-200" label="Save" />
        <ToolbarButton icon={faTrash} onClick={() => onDeleteTextBlock(textBlock.id)} className="bg-red-200" label="Delete" />
        <ToolbarButton icon={faPlus} onClick={() => onAddTextBlock()} className="bg-yellow-200" label="Add" />
      </div>

      <button
        className="resize-handle"
        style={{
          position: "absolute",
          display: "inline",
          right: 0,
          width: "20px",
          height: "20px",
          cursor: "nwse-resize",
          touchAction: "none",
        }}
        onMouseDown={handleResizeStart}
        onTouchStart={handleResizeStart}
      >
        ⇔
      </button>
    </div>
  );
};

export default Toolbar;