import React from 'react';
import ImageArea from './ImageArea';
import { BookOpenIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

// const BookCover = ({
//   isEditable,
//   coverImages,
//   isGeneratingImages,
//   selectedImageIndex
// }) => {
//   return (
//     <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center w-full h-full">
//       <ImageArea
//         isEditable={isEditable}
//         imageAreaPages={coverImages}
//         pageNumber={0}
//         isGeneratingImages={isGeneratingImages}
//         selectedImageIndex={selectedImageIndex}
//       />
//     </div>
//   );
// };


const BookCover = ({
  isEditable,
  coverImage,
  isGeneratingImages,
  selectedImageIndex
}) => {
  console.log("BOOK COVER: ", coverImage.length);
  return (
    <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center w-full h-full">
      <ImageArea
        isEditable={isEditable}
        passedImages={[coverImage] || []}
        pageNumber={0}
        isGeneratingImages={isGeneratingImages}
        selectedImageIndex={selectedImageIndex}
      />
    </div>
  );
};

// const BookCover = ({
//   isEditable,
//   coverImage,
//   isGeneratingImages,
//   selectedImageIndex
// }) => {
//   console.log("BOOK COVER: ", coverImage.length);
//   return (
//     <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center w-full h-full">
//       <ImageArea
//         isEditable={isEditable}
//         passedImages={[coverImage] || []}
//         pageNumber={0}
//         isGeneratingImages={isGeneratingImages}
//         selectedImageIndex={selectedImageIndex}
//       />
//     </div>
//   );
// };

export default BookCover;
// const BookCover = ({
//   isEditable,
//   coverImages,
//   isGeneratingImages,
//   title,
//   onImageToggle,
//   onPromptEdit
// }) => {
//   return (
//       <ImageArea
//         isEditable={isEditable}
//         imageAreaPages={coverImages}
//         pageNumber={0}
//         isGeneratingImages={isGeneratingImages}
//         onImageToggle={onImageToggle}
//         onPromptEdit={onPromptEdit}
//       />
//   );
// };
// export default BookCover;
