import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStoryById, deleteStoryById, copyBook } from '../services/storyService';
import BuyModal from './BuyModal';
import { BookOpenIcon, ShoppingCartIcon, Square2StackIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

const IconButton = ({ onClick, icon: Icon, label, color }) => (
  <button 
    onClick={onClick} 
    className={`group flex items-center p-2 rounded-full hover:rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${color}`}
  >
    <Icon className="w-6 h-6 mr-0 group-hover:mr-2 transition-all duration-300" />
    <span className="w-0 overflow-hidden group-hover:w-auto transition-all duration-300 whitespace-nowrap">
      {label}
    </span>
  </button>
);

const PremadeBookCard = ({ bookId, onPreviewOpen, onDelete }) => {
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchBook = useCallback(async () => {
    try {
      const bookData = await getStoryById(bookId);
      setBook(bookData);
    } catch (error) {
      console.error('Failed to fetch book data:', error);
    } finally {
      setLoading(false);
    }
  }, [bookId]);

  useEffect(() => {
    fetchBook();
  }, [fetchBook]);

  const handlePreviewBook = () => onPreviewOpen(book);
  const handleBuyBook = () => setIsBuyModalOpen(true);
  const handleViewEditStory = () => book && navigate(`/writer/${book.bookId}`);

  const handleDeleteBook = async () => {
    if (window.confirm('Are you sure you want to delete this book?')) {
      try {
        await deleteStoryById(bookId);
        onDelete(bookId);
        alert('Book successfully deleted');
      } catch (error) {
        console.error('Failed to delete book:', error);
        alert(`Failed to delete book: ${error.message}`);
      }
    }
  };

  const handleCopyBook = async () => {
    try {
      const newBook = await copyBook(bookId);
      alert('Book successfully copied');
      navigate(`/writer/${newBook.bookId}`);
    } catch (error) {
      console.error('Failed to copy book:', error);
      alert(`Failed to copy book: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col h-full p-4 border border-gray-200 rounded-lg shadow-md animate-pulse bg-white">
        <div className="w-full aspect-w-3 aspect-h-4 bg-gray-300 rounded-md mb-4"></div>
        <div className="h-6 bg-gray-300 rounded mb-3"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="grow"></div>
        <div className="flex justify-around">
          <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
          <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
          <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
        </div>
      </div>
    );
  }

  if (!book) {
    return <div className="flex items-center justify-center h-full text-gray-500">Missing book data</div>;
  }

  const defaultCoverImage = book.coverImages?.find(img => img.is_front_cover && img.is_default) || {};

  return (
    <div className="flex flex-col h-full p-2 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 bg-white">
      <div className="w-full aspect-w-3 aspect-h-4 bg-gray-200 rounded-md overflow-hidden mb-4 group">
        <img
          src={defaultCoverImage.imageUrl || 'https://via.placeholder.com/150'}
          alt={book.title}
          className="w-full h-full object-cover object-center transform transition-transform duration-300 group-hover:scale-105"
        />
      </div>
      <div className="grow"></div>
      <div className="flex justify-around">
        <IconButton onClick={handlePreviewBook} icon={BookOpenIcon} label="Preview" color="bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500" />
        <IconButton onClick={handleBuyBook} icon={ShoppingCartIcon} label="Buy" color="bg-green-400 text-white hover:bg-green-600 focus:ring-green-500" />
        <IconButton onClick={handleCopyBook} icon={Square2StackIcon} label="Copy" color="bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-yellow-500" />
        {location.pathname.includes('profile') && (
          <>
            <IconButton onClick={handleViewEditStory} icon={PencilIcon} label="Edit" color="bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-500" />
            <IconButton onClick={handleDeleteBook} icon={TrashIcon} label="Delete" color="bg-red-500 text-white hover:bg-red-600 focus:ring-red-500" />
          </>
        )}
      </div>
      {isBuyModalOpen && (
        <BuyModal
          isOpen={isBuyModalOpen}
          handleClose={() => setIsBuyModalOpen(false)}
          book={book}
          onPreviewOpen={onPreviewOpen}
        />
      )}
    </div>
  );
};

export default PremadeBookCard;
