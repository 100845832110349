export const sections = [
  {
    title: "Character Details",
    tooltip: "Enter details about the main character of the story",
    steps: [
      {
        label: "Name",
        type: "text",
        name: "characterName",
        tooltip: "Enter the name of the main character",
      },
      // {
      //   label: "Age",
      //   type: "text",
      //   name: "age",
      //   tooltip: "Child age",
      // },
      // {
      //   label: "Gender",
      //   type: "radio",
      //   name: "gender",
      //   className: "centered-radio",
      //   options: ["Boy", "Girl"],
      //   tooltip: "Child's Gender",
      // },
      {
        label: "Select Character",
        type: "character-select",
        name: "userPhotosRequestId",
        tooltip: "Choose a character image",
      },
    ],
  },
  {
    title: "Story Settings",
    tooltip: "Set the main theme and conflict of the story",
    steps: [
      {
        label: "Theme and Message",
        type: "text-dropdown",
        name: "theme",
        options: [
          "Friendship (e.g., Winnie the Pooh)",
          "Adventure (e.g., Where the Wild Things Are)",
          "Learning (e.g., The Very Hungry Caterpillar)",
          "Being yourself (e.g., Elmer the Patchwork Elephant)",
          "Perseverance (e.g., The Little Engine That Could)",
          "Kindness (e.g., Corduroy)",
        ],
        tooltip: "Select the main theme or message of the story",
      },
      {
        label: "Type of Conflict",
        type: "text-dropdown",
        name: "challenge",
        options: [
          "win a competition",
          "find their way home",
          "uncover a secret",
          "save a friend in trouble",
          "learn a new skill",
          "navigate through a maze",
        ],
        tooltip:
          "Choose the type of conflict or challenge the main character faces",
      },
      {
        label: "Setting",
        type: "text-dropdown",
        name: "setting",
        options: [
          "Everyday world (e.g., Clifford the Big Red Dog)",
          "Fantasy world (e.g., Alice in Wonderland)",
          "Natural world (e.g., The Lorax)",
        ],
        tooltip: "Select the setting or world in which the story takes place",
      },
      {
        label: "Target Age Group",
        type: "text-dropdown",
        name: "ageGroup",
        options: ["Closer to 3", "Around 4", "Closer to 5"],
        tooltip: "Choose the target age group for the story",
      },
      {
        label: "Length (number of pages)",
        type: "number",
        name: "length",
        min: 1,
        max: 50,
        tooltip: "Enter the desired number of pages for the story",
      },
    ],
  },
  {
    title: "Story Dynamics",
    tooltip: "Choose dynamic elements to enhance the story",
    steps: [
      {
        label: "Narrative Repetition",
        type: "checkbox",
        name: "narrativeRepetition",
        tooltip: "Include repetitive phrases or elements in the narrative",
      },
      {
        label: "Character Repetition",
        type: "checkbox",
        name: "characterRepetition",
        tooltip: "Feature recurring characters throughout the story",
      },
      {
        label: "Rhythm and Rhyme",
        type: "checkbox",
        name: "rhyme",
        tooltip: "Incorporate rhythmic or rhyming elements in the story",
      },
    ],
  },
  {
    title: "Additional Settings",
    steps: [
      {
        label: "Image Layout",
        type: "radio",
        name: "imageLayout",
        options: [
          "Single-page image",
          "Double-page image",
        ],
      },
      {
        label: "Story Prompt (Optional)",
        type: "text",
        name: "storyPrompt",
        tooltip: "Enter additional notes or context for the story (optional)",
      },
    ],
  },
];