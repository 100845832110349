import {
  getAccessToken,
  refreshAccessToken,
  setAccessToken,
  getRefreshToken,
} from "../utils/auth";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`
// export const uploadPhotos = async (userId, files) => {
//     const formData = new FormData();
//     formData.append("userId", userId);
//     formData.append("frontal_photo", files[0]);
//     formData.append("profile_photo", files[1]);

//   const token = getAccessToken();
//   const response = await fetch(`${base_backend}/photos/`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     body: formData,
//   });

//   if (!response.ok) {
//     if (response.status === 401) {
//       const newToken = await refreshAccessToken(getRefreshToken());
//       if (!newToken) {
//         throw new Error("Unable to refresh token");
//       }
//       setAccessToken(newToken);
//       return uploadPhotos(userId, files); // Retry with new token
//     } else {
//       throw new Error("Failed to upload photos");
//     }
//   }

//   return await response.json();
// };

export const uploadPhotos = async (userId, frontalPhoto, profilePhoto, age, gender) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("frontal_photo", frontalPhoto); // The frontal photo file
  formData.append("profile_photo", profilePhoto); // The profile photo file
  formData.append("age", age); // The age input
  formData.append("gender", gender); // The gender input

  const token = getAccessToken();
  const response = await fetch(`${base_backend}/photos/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // Token in header
    },
    body: formData, // FormData containing files and other fields
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return uploadPhotos(userId, frontalPhoto, profilePhoto, age, gender); // Retry with new token
    } else {
      throw new Error("Failed to upload photos");
    }
  }

  return await response.json();
};


export const getPhotos = async (userId) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/photos/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return getPhotos(userId); // Retry with new token
    } else {
      throw new Error("Failed to fetch photos");
    }
  }

  return await response.json();
};

export const deletePhotoPack = async (requestId) => {
  const token = getAccessToken();
  const response = await fetch(
    `${base_backend}/photos/?requestId=${requestId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return deletePhotoPack(requestId); // Retry with new token
    } else {
      throw new Error("Failed to delete photo pack");
    }
  }

  return await response.json();
};
